import { PartialHosted } from '../../types/orgTypes'

export const hostedUrlTest = (hosted?: PartialHosted): string | false => {
  if (hosted?.hasHostedPage && !hosted?.url) return 'Url required'
  if (hosted?.url?.includes(' ')) return 'Url cannot contain spaces'
  return false
}

export const getHostedErrors = (hosted?: PartialHosted): string[] => {
  const errorArray: string[] = []

  const add = (errorTest: (hosted?: PartialHosted) => string | false): void => {
    if (!hosted) errorArray.push('Can\'t find hosted page')

    const error = errorTest(hosted)
    if (error) errorArray.push(error)
  }

  add(hostedUrlTest)

  return errorArray
}

export const isHostedValid = (hosted?: PartialHosted): boolean => {
  if (getHostedErrors(hosted).length) return false
  return true
}
