import {
  Button,
  ButtonSize,
  ButtonStyle,
  enumKeyByValue,
  enumToOptions,
  FontSize,
  IImageUploadResponse,
  ImageType,
  RichField,
  SelectField,
  stringsToOptions,
  stringToOption,
  UploadImageField,
} from '@one-tree/library'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { uploadImage } from '../../../helpers/APIhelper'
import { Alignment } from '../../../types/orgTypes'
import { GOOGLE_FONTS } from './GoogleFonts'

export function HostedFontFamily(props: {
  value?: string
  onChange: (value?: string) => void
}): ReactElement {
  const { value, onChange } = props

  return (
    <SelectField
      label="Font family"
      placeholder="Select font family..."
      options={stringsToOptions(GOOGLE_FONTS)}
      value={stringToOption(value || '')}
      onChange={(option): void => onChange(option?.value)}
    />
  )
}

export function HostedAlignment(props: {
  value?: Alignment
  onChange: (value?: Alignment) => void
}): ReactElement {
  const { value, onChange } = props

  return (
    <SelectField
      label="Alignment"
      placeholder="Select alignment..."
      options={enumToOptions(Alignment)}
      value={
        value ? { value, label: enumKeyByValue(Alignment, value) } : undefined
      }
      onChange={(option): void => onChange(option?.value)}
    />
  )
}

export function HostedFontSize(props: {
  value?: FontSize
  onChange: (value?: FontSize) => void
}): ReactElement {
  const { value, onChange } = props
  const fontSizes = enumToOptions(FontSize)

  return (
    <SelectField
      label="Font size"
      placeholder="Select font size..."
      options={fontSizes}
      value={
        value ? { value, label: enumKeyByValue(FontSize, value) } : undefined
      }
      onChange={(option): void => onChange(option?.value)}
    />
  )
}

const CustomUploadImage = styled(UploadImageField)`
  width: 400px;
  margin-bottom: 5px;
`
export function HostedImageUpload(props: {
  label: string
  imageType: ImageType
  imageFile?: string | null
  subtitle: string
  cropPixelAspect?: { x: number; y: number }
  onSuccess: (response: IImageUploadResponse) => void
  onRemove?: () => void
  minimumAspect?: { x: number; y: number }
  canCrop?: boolean
  noResizeOrConvert?: true
}): ReactElement {
  const {
    label,
    imageType,
    imageFile,
    subtitle,
    cropPixelAspect,
    onSuccess,
    onRemove,
    minimumAspect,
    canCrop,
    noResizeOrConvert,
  } = props

  return (
    <div>
      <CustomUploadImage
        label={label}
        imageType={imageType}
        previewUrl={imageFile || ''}
        subtitle={subtitle}
        cropPixelAspect={cropPixelAspect}
        upload={uploadImage}
        onSuccess={onSuccess}
        minimumAspect={minimumAspect}
        canCrop={canCrop}
        noResizeOrConvert={noResizeOrConvert}
      />
      {imageFile && (
        <Button
          buttonStyle={ButtonStyle.Secondary}
          buttonSize={ButtonSize.Mini}
          onClick={onRemove}
          showConfirmation={true}
        >
          Remove image
        </Button>
      )}
    </div>
  )
}

const StyledRichField = styled(RichField)`
  width: 100%;
  max-width: 580px;
`
export function HostedTextArea(props: {
  value?: string
  onChange: (value?: string) => void
}): ReactElement {
  const { value, onChange } = props

  return (
    <StyledRichField
      label="Text"
      placeholder="Enter text..."
      value={value}
      onChange={onChange}
      hasFontSize={true}
    />
  )
}
