import {
  AsyncButton,
  Card,
  CardContent,
  CardHeader,
  CardSize,
  IFilters,
  ISelectOption,
  Order,
  stringsToOptions,
  Table,
} from '@one-tree/library'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import download from 'downloadjs'
import Page from '../../components/Page'
import { useOrganisation } from '../../context/OrganisationProvider'
import { exportPerformance, getPerformance } from '../../helpers/APIhelper'
import { fetchGroups } from '../../helpers/groupHelper'
import { productOptions } from '../../helpers/TableHelper'
import { IPerformance } from '../../types/orgTypes'
import { Path } from '../../types/routes'
import { getTypeOptions } from '../organisations/OrgHelper'
import { getPerformanceColumns, PerformanceTableState } from './PerformanceHelper'

export default function Performance(): ReactElement {
  const history = useHistory()
  const navigate = (
    path: Path,
    id?: string | number,
    usersFilterOrg?: number,
  ): void => {
    history.push(`${path}/${id || ''}`, { orgId: usersFilterOrg })
  }

  const performanceColumns = getPerformanceColumns(navigate)
  const { types } = useOrganisation()

  const [groupOptions, setGroupOptions] = useState<ISelectOption<number>[]>([])
  const groups = async (): Promise<void> => setGroupOptions(await fetchGroups())
  useEffect(() => {
    groups()
  }, [])

  const filters: IFilters<IPerformance>[] = [
    {
      accessor: 'status',
      options: stringsToOptions(['Active', 'Suspended', 'Cancelled', 'Draft']),
      placeholder: 'Select status...',
      value: 'Active',
    },
    {
      accessor: 'group',
      options: groupOptions,
      placeholder: 'Select group...',
    },
    {
      accessor: 'type',
      options: getTypeOptions(types),
      placeholder: 'Select type...',
    },
    {
      accessor: 'format',
      options: productOptions,
      placeholder: 'Select product...',
    },
  ]

  const [tableState, setTableState] = useState<PerformanceTableState>()

  const exportCsv = async (): Promise<void> => {
    const res = await exportPerformance({ ...tableState })
    if (res) download(res, 'Performance.csv')
  }

  return (
    <Page>
      <Card cardSize={CardSize.Full}>
        <CardHeader title="Organisation performance">
          <AsyncButton onClick={exportCsv}>Export CSV</AsyncButton>
        </CardHeader>
        <CardContent>
          <Table
            fetch={getPerformance}
            columns={performanceColumns}
            dates={{
              startDate: moment().subtract(1, 'year').add(1, 'day').unix(),
              endDate: moment().unix(),
            }}
            filters={filters}
            rowLink={{ route: Path.Masquerade, slug: 'id' }}
            getState={setTableState}
            options={{
              orderBy: 'incomeTotal',
              orderDirection: Order.Desc,
            }}
            search={true}
          />
        </CardContent>
      </Card>
    </Page>
  )
}
