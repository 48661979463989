import {
  capitalize,
  InvoiceAddress,
  InvoiceDetail,
  InvoiceGrid,
  InvoiceItem,
  parseString,
  transformDate,
  TransformDateFormat,
} from '@one-tree/library'
import React, { ReactElement } from 'react'
import { IInvoice, IInvoiceItem } from '../../types/adminTypes'
import { invoiceStatusMap } from '../invoices/InvoiceHelper'

const formatInvoiceDate = (date?: number): string => (date ? transformDate(date, TransformDateFormat.NumbersAndSlashes) : '-')

interface IProps {
  invoice: IInvoice
}
export default function InvoiceLayout(props: IProps): ReactElement {
  const { invoice } = props
  const {
    id,
    date,
    dueDate,
    paidDate,
    paymentMethod,
    status,
    items,
    total,
    subtotal,
    currencyCode,
    address: {
      name,
      addressLineOne,
      addressLineTwo,
      addressTown,
      addressCounty,
      addressPostcode,
    },
  } = invoice

  const method = invoice && paymentMethod === 'gocardless'
    ? 'GoCardless'
    : capitalize(paymentMethod)

  return (
    <InvoiceGrid>
      <section className="billingFrom">
        <InvoiceAddress
          name="One Tree Software"
          lineOne="Long Barn"
          lineTwo="Priston"
          town="Bath"
          postcode="BA2 9EE"
        />
      </section>
      <section className="details">
        <InvoiceDetail header="Invoice ID" detail={id} />
        <InvoiceDetail header="Invoice date" detail={formatInvoiceDate(date)} />
        {!paidDate ? (
          <InvoiceDetail
            header="Due date"
            detail={formatInvoiceDate(dueDate)}
          />
        ) : (
          <InvoiceDetail
            header="Paid date"
            detail={formatInvoiceDate(paidDate)}
          />
        )}
        <InvoiceDetail header="Payment method" detail={method} />
        <div className="header">Status:</div>
        <div className="info">{invoiceStatusMap.get(status)}</div>
      </section>
      <section className="billingTo">
        <div>Billing to:</div>
        <InvoiceAddress
          name={name || ''}
          lineOne={addressLineOne}
          lineTwo={addressLineTwo}
          town={addressTown}
          county={addressCounty}
          postcode={addressPostcode}
        />
      </section>
      <section className="items">
        <div className="description">
          <strong>Description</strong>
        </div>
        <div className="amount">
          <strong>Amount</strong>
        </div>
        {items?.map((item: IInvoiceItem) => (
          <InvoiceItem
            key={item.id}
            description={parseString(item.description)}
            amount={item.value}
            currencyCode={currencyCode}
          />
        ))}
        <InvoiceItem
          description="Sub-total"
          amount={subtotal || 0}
          currencyCode={currencyCode}
        />
        <InvoiceItem
          description="VAT @ 20%"
          amount={total - subtotal || 0}
          currencyCode={currencyCode}
        />
        <InvoiceItem
          description="Total"
          amount={total || 0}
          currencyCode={currencyCode}
        />
      </section>
    </InvoiceGrid>
  )
}
