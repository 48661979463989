import {
  Card,
  CardContent,
  CardHeader,
  CurrencyCode,
  formatCurrency,
  IColumn,
  Order,
  Table,
} from '@one-tree/library'
import React, { ReactElement, ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import TimeAgo from 'react-timeago'
import DisplayOrganisation from '../../components/DisplayOrganisation'
import { useOrganisation } from '../../context/OrganisationProvider'
import { getSales } from '../../helpers/APIhelper'
import { IAdminCards } from '../../types/adminTypes'
import { IAdminOrg } from '../../types/orgTypes'
import { Path } from '../../types/routes'

const getColumns = (organisations: IAdminOrg[]): IColumn<IAdminCards>[] => {
  const columns: IColumn<IAdminCards>[] = [
    { accessor: 'id', header: 'ID', sorting: false },
    {
      accessor: 'value',
      header: 'Value',
      sorting: false,
      display: ({ value }): ReactNode => formatCurrency(value, CurrencyCode.GBP),
    },
    {
      accessor: 'organisationId',
      header: 'Organisation',
      sorting: false,
      display: ({ organisationId }): ReactNode => {
        const { name, format } = organisations.find(({ id }) => id === organisationId) || {}
        return <DisplayOrganisation name={name} format={format} />
      },
    },
    {
      accessor: 'purchaseDate',
      header: 'Purchased',
      sorting: false,
      display: ({ purchaseDate }): ReactNode => (
        <TimeAgo
          style={{ whiteSpace: 'nowrap' }}
          date={purchaseDate * 1000}
          minPeriod={60}
        />
      ),
    },
  ]
  return columns
}

export default function LiveSales(): ReactElement {
  const { organisations } = useOrganisation()
  const columns = getColumns(organisations)

  const history = useHistory()
  const navigate = (path: Path, id: number): void => {
    history.push(`${path}?${id.toString()}`, { backLink: Path.Reporting })
  }

  return (
    <Card style={{ flex: '1' }}>
      <CardHeader title="Live purchases" />
      <CardContent>
        <Table
          fetch={getSales}
          columns={columns}
          options={{
            orderBy: 'purchaseDate',
            orderDirection: Order.Desc,
            limit: 4,
          }}
          pagination={false}
          rowLink={{
            onClick: ({ organisationId }): void => navigate(Path.Sales, organisationId),
          }}
        />
      </CardContent>
    </Card>
  )
}
