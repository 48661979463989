import {
  alertSuccess,
  AsyncButton,
  Button,
  ButtonStyle,
  enumToOptions,
  InputGroup,
  Modal,
  OrgRole,
  SelectField,
} from '@one-tree/library'
import React, { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { useOrganisation } from '../../context/OrganisationProvider'
import { addOrgToUser } from '../../helpers/APIhelper'
import { PartialUser } from '../../types/userTypes'
import { getOrgOptions } from '../organisations/OrgHelper'
import { orgRoleMap } from '../users/UsersHelper'

const ModalContent = styled.div`
  display: grid;
  grid-row-gap: 10px;
  min-width: 300px;
`
const StyledInputGroup = styled(InputGroup)`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`
interface IProps {
  addOrgModal: number | boolean
  setAddOrgModal: (value: boolean) => void
  user: PartialUser
}
export default function AddUserOrg(props: IProps): ReactElement {
  const { addOrgModal, setAddOrgModal, user } = props

  const [addOrg, setAddOrg] = useState<number>()
  const [addRole, setAddRole] = useState<OrgRole>()
  const close = (): void => {
    setAddOrg(undefined)
    setAddRole(undefined)
    setAddOrgModal(false)
  }

  const { organisations } = useOrganisation()
  const orgOptions = getOrgOptions(organisations)

  const addNewOrg = async (): Promise<void> => {
    if (user.userId && addOrg && addRole) {
      const res = await addOrgToUser({
        userId: user.userId,
        id: addOrg,
        role: addRole,
      })

      if (res) {
        alertSuccess('Organisation added')
        close()
      }
    }
  }

  return (
    <Modal modalOpen={Boolean(addOrgModal)} onModalClose={close}>
      <ModalContent>
        <SelectField
          placeholder="Select organisation..."
          label="Organisation"
          options={orgOptions}
          onChange={(option): void => setAddOrg(option?.value)}
          value={
            addOrg
              ? {
                value: addOrg,
                label: organisations.find((org) => org.id === addOrg)?.name,
              }
              : undefined
          }
        />
        <SelectField
          placeholder="Select role..."
          label="Role"
          options={enumToOptions(OrgRole)}
          onChange={(option): void => setAddRole(option?.value)}
          value={
            addRole
              ? { value: addRole, label: orgRoleMap.get(addRole) }
              : undefined
          }
        />
        <StyledInputGroup>
          <Button buttonStyle={ButtonStyle.Secondary} onClick={close}>
            Cancel
          </Button>
          <AsyncButton buttonStyle={ButtonStyle.Action} onClick={addNewOrg}>
            Add
          </AsyncButton>
        </StyledInputGroup>
      </ModalContent>
    </Modal>
  )
}
