import { colors, OrganisationFormat } from '@one-tree/library'
import React, { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

const Name = styled.span`
  color: ${({ theme: { color } }): string => color || ''};
  font-weight: bold;
`
interface IDisplayOrganisationProps {
  name?: string
  format?: OrganisationFormat
}
export default function DisplayOrganisation(
  props: IDisplayOrganisationProps,
): ReactElement {
  const { name, format } = props

  const color = (): string => {
    switch (format) {
    case OrganisationFormat.Tickets:
      return colors.tickets
    case OrganisationFormat.SpecialOffers:
      return colors.specialOffers
    case OrganisationFormat.GiftVouchers:
      return colors.giftVouchers
    default:
      return colors.blue
    }
  }

  return <Name theme={{ color }}>{name || 'New organisation'}</Name>
}

const Row = styled.div`
  white-space: nowrap;
`
export function OrgTitle(props: IDisplayOrganisationProps): ReactElement {
  const { pathname } = useLocation()

  const title = (): string => {
    const parts = pathname.split('/')
    const lastPart = parts[parts.length - 1]

    switch (lastPart) {
    case 'hosted':
      return 'hosted page'
    case 'finance':
      return 'finance'
    default:
      return 'details'
    }
  }

  return (
    <Row>
      Edit {title()}: <DisplayOrganisation {...props} />
    </Row>
  )
}
