import {
  ButtonSize,
  ButtonStyle,
  FormattedDateTime,
  IColumn,
  Role,
  transformDate,
  TransformDateFormat,
  AsyncButton,
  OrgRole,
  AdminRole,
  capitalize,
  RequestWithFilters,
  IFilters,
} from '@one-tree/library'
import React, { ReactNode } from 'react'
import { IUser, IUserOrg, UserStatus } from '../../types/userTypes'

export const orgRoleMap = new Map<OrgRole, string>([
  [OrgRole.OrganisationAdmin, 'Admin'],
  [OrgRole.OrganisationUser, 'User'],
  [OrgRole.OrganisationRedeemer, 'Redeemer'],
])
export const adminRoleMap = new Map<AdminRole, string>([
  [AdminRole.SuperAdmin, 'Super admin'],
  [AdminRole.SubSuperAdmin, 'Sub super admin'],
  [AdminRole.Dispatch, 'Dispatch'],
])
export const roleMap = new Map<Role, string>([
  [Role.SuperAdmin, 'Super admin'],
  [Role.SubSuperAdmin, 'Sub super admin'],
  [Role.OrganisationAdmin, 'Admin'],
  [Role.OrganisationUser, 'User'],
  [Role.OrganisationRedeemer, 'Redeemer'],
  [Role.Dispatch, 'Dispatch'],
])

export const getUserColumns = (): IColumn<IUser>[] => {
  const columns: IColumn<IUser>[] = [
    {
      accessor: 'userId',
      header: 'User ID',
    },
    {
      accessor: 'emailAddress',
      header: 'Email address',
    },
    {
      accessor: 'name',
      header: 'Name',
    },
    {
      accessor: 'lastLogin',
      header: 'Last login',
      display: ({ lastLogin }) => (lastLogin ? <FormattedDateTime timestamp={lastLogin} /> : '-'),
    },
    {
      accessor: 'status',
      header: 'Status',
      display: ({ status }) => capitalize(status),
    },
  ]
  return columns
}

export const userStatusMap = new Map<UserStatus, string>([
  [UserStatus.Active, 'Active'],
  [UserStatus.Inactive, 'Inactive'],
  [UserStatus.Deleted, 'Deleted'],
])

export const getUserOrgColumns = (
  setEditOrgModal: (id: number) => void,
): IColumn<IUserOrg>[] => {
  const controls = ({ id }: IUserOrg): ReactNode => (
    <AsyncButton
      onClick={(): void => setEditOrgModal(id)}
      buttonStyle={ButtonStyle.Secondary}
      buttonSize={ButtonSize.Table}
    >
      Edit
    </AsyncButton>
  )

  return [
    {
      accessor: 'name',
      header: 'Organisation',
    },
    {
      accessor: 'role',
      header: 'Role',
      sorting: false,
      display: ({ role }): string => orgRoleMap.get(role) || '',
    },
    {
      accessor: 'lastLogin',
      header: 'Last login',
      display: ({ lastLogin }) => (lastLogin
        ? transformDate(lastLogin, TransformDateFormat.NumbersAndSlashes)
        : '-'),
    },
    {
      header: 'Controls',
      sorting: false,
      display: controls,
    },
  ]
}

export type UserTableState = RequestWithFilters<IUser, IFilters<IUser>[]>
