import {
  formatCurrency,
  FormattedDateTime,
  IColumn,
  IFilters,
  transformDate,
  TransformDateFormat,
  RequestWithFilters,
} from '@one-tree/library'
import React, { ReactNode } from 'react'
import DisplayOrganisation from '../../components/DisplayOrganisation'
import { ClickOrg } from '../../helpers/TableHelper'
import { IInvoice, InvoiceStatus } from '../../types/adminTypes'
import { IAdminOrg } from '../../types/orgTypes'
import { Path } from '../../types/routes'

export const invoiceStatusMap = new Map<InvoiceStatus, string>([
  [InvoiceStatus.Cancelled, 'Cancelled'],
  [InvoiceStatus.Open, 'Open'],
  [InvoiceStatus.Paid, 'Paid'],
  [InvoiceStatus.PaidGoCardless, 'Paid GoCardless'],
])

export const getInvoiceColumns = (
  organisations: IAdminOrg[],
  navigate: (path: Path, id: number) => void,
): IColumn<IInvoice>[] => {
  const columns: IColumn<IInvoice>[] = [
    {
      accessor: 'id',
      header: 'Invoice ID',
    },
    {
      accessor: 'organisationId',
      header: 'Organisation',
      sorting: false,
      display: ({ organisationId }): ReactNode => {
        const { name, format } = organisations.find(({ id }) => id === organisationId) || {}
        return (
          <ClickOrg
            onClick={(): void => navigate(Path.Organisations, organisationId)}
          >
            <DisplayOrganisation name={name} format={format} />
          </ClickOrg>
        )
      },
      clickable: false,
    },
    {
      accessor: 'total',
      header: 'Value',
      display: ({ total, organisationId }): ReactNode => {
        const thisOrg = organisations.find(({ id }) => organisationId === id)
        return formatCurrency(total, thisOrg?.currencyCode)
      },
    },
    {
      accessor: 'date',
      header: 'Date',
      display: ({ date: issued }) => transformDate(issued, TransformDateFormat.NumbersAndSlashes),
    },
    {
      accessor: 'dueDate',
      header: 'Due date',
      display: ({ dueDate: due }) => transformDate(due, TransformDateFormat.NumbersAndSlashes),
    },
    {
      accessor: 'paidDate',
      header: 'Paid date',
      display: ({ paidDate: paid }) => (paid ? <FormattedDateTime timestamp={paid} /> : '-'),
    },
    {
      accessor: 'status',
      header: 'Status',
      display: ({ status }): string => {
        const statusString = invoiceStatusMap.get(status)
        return statusString || '-'
      },
    },
  ]
  return columns
}

export type InvoiceTableState = RequestWithFilters<
  IInvoice,
  IFilters<IInvoice>[]
>
