import {
  Card,
  CardContent,
  CardHeader,
  colors,
  CurrencyCode,
  formatCurrency,
  SelectField,
  useDebounce,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { getReportingSummary } from '../../helpers/APIhelper'
import {
  IReportingSummaryData,
  ReportingSummaryCurrency,
  ReportingSummaryInterval,
} from '../../types/APItypes'
import FadeIn from './FadeIn'

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 10px;
  justify-content: space-between;
`
const Column = styled.div`
  border-left: 2px solid ${colors.gray};
  padding: 0 10px 0 5px;
`
const Header = styled.td`
  color: ${({ theme: { color } }): string => color};
  font-size: 1.1rem;
  padding-top: 5px;
  white-space: nowrap;
`
const Cell = styled.td`
  vertical-align: baseline;
  padding-top: 10px;
`
const Label = styled(Cell)`
  color: ${colors.darkerGray};
  padding-right: 5px;
`
const Value = styled(Cell)`
  color: ${({ theme: { color } }): string => color};
  font-size: 1rem;
  font-weight: bold;
`
function ProductColumn(props: {
  title: string
  sold: number
  value: number
  income: number
  color?: string
}): ReactElement {
  const {
    title, sold, value, income, color,
  } = props

  return (
    <Column>
      <table>
        <tbody>
          <tr>
            <Header colSpan={2} theme={{ color }}>
              {title}
            </Header>
          </tr>
          <tr>
            <Label>Sold</Label>
            <Value theme={{ color }}>{sold}</Value>
          </tr>
          <tr>
            <Label>Value</Label>
            <Value theme={{ color }}>
              {value ? formatCurrency(value, CurrencyCode.GBP, true) : '0'}
            </Value>
          </tr>
          <tr>
            <Label>Income</Label>
            <Value theme={{ color }}>
              {income ? formatCurrency(income, CurrencyCode.GBP, true) : '0'}
            </Value>
          </tr>
        </tbody>
      </table>
    </Column>
  )
}

export default function SalesWidget(): ReactElement {
  const options = [
    { label: 'Today', value: ReportingSummaryInterval.Day },
    { label: 'This week', value: ReportingSummaryInterval.Week },
    { label: 'This month', value: ReportingSummaryInterval.Month },
  ]

  const [range, setRange] = useState(options[0])

  const [data, setData] = useState<IReportingSummaryData>()
  const [loading, setLoading] = useState(true)
  const isLoading = !data || loading

  const fetchData = async (): Promise<void> => {
    const res = await getReportingSummary({
      currency: ReportingSummaryCurrency.GBP,
      interval: range.value,
    })
    if (res) setData(res)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, [useDebounce(range, 500)])

  return (
    <Card style={{ width: '650px' }}>
      <CardHeader title={`Sales ${range.label.toLowerCase()}`}>
        <SelectField
          options={options}
          value={range}
          onChange={(option): void => {
            if (option) setRange(option)
          }}
        />
      </CardHeader>
      <CardContent>
        <FadeIn ready={!isLoading} style={{ height: '120px' }}>
          {data && (
            <Container>
              <ProductColumn
                title="Gift vouchers"
                sold={data.gv.count}
                value={data.gv.totalValue}
                income={data.gv.totalCommission}
                color={colors.giftVouchers}
              />
              <ProductColumn
                title="Special offers"
                sold={data.so.count}
                value={data.so.totalValue}
                income={data.so.totalCommission}
                color={colors.specialOffers}
              />
              <ProductColumn
                title="Tickets"
                sold={data.te.count}
                value={data.te.totalValue}
                income={data.te.totalCommission}
                color={colors.tickets}
              />
              <ProductColumn
                title="Total sales"
                sold={data.total.count}
                value={data.total.totalValue}
                income={data.total.totalCommission}
              />
            </Container>
          )}
        </FadeIn>
      </CardContent>
    </Card>
  )
}
