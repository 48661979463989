import {
  Card, CardSize, CardTab, CardTabs,
} from '@one-tree/library'
import React, { ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'
import Page from '../../components/Page'
import { Path } from '../../types/routes'
import Pending from './Pending'
import SalesTable from './SalesTable'

export default function Sales(): ReactElement {
  return (
    <Page>
      <Card cardSize={CardSize.Full}>
        <CardTabs>
          <CardTab title="Sales" to={Path.Sales} />
          <CardTab title="Pending" to={Path.Pending} />
        </CardTabs>

        <Switch>
          <Route exact={true} path={Path.Sales} component={SalesTable} />
          <Route
            exact={true}
            path={[Path.Pending, `${Path.Pending}/:id`]}
            component={Pending}
          />
        </Switch>
      </Card>
    </Page>
  )
}
