import {
  AsyncButton,
  Button,
  ButtonStyle,
  InputField,
  ISelectOption,
  Modal,
  OrganisationFormat,
  SelectField,
} from '@one-tree/library'
import React, { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { cloneOrganisation } from '../../helpers/APIhelper'
import { productOptions } from '../../helpers/TableHelper'

const Content = styled.div`
  display: grid;
  grid-row-gap: 15px;
  min-width: 260px;
  h3 {
    margin: 0;
  }
`
const ModalButtons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`
interface IProps {
  id?: number
  modalOpen: boolean
  modalClose: () => void
}
export default function OrgClone(props: IProps): ReactElement {
  const { id, modalOpen, modalClose } = props
  const [name, setName] = useState('')
  const [product, setProduct] = useState<ISelectOption<OrganisationFormat>>()

  const close = (): void => {
    setProduct(undefined)
    setName('')
    modalClose()
  }

  const invalid = !name || !product

  const history = useHistory()

  const clone = async (): Promise<void> => {
    if (!id || invalid) return
    const res = await cloneOrganisation({ id, name, format: product.value })
    if (res) {
      history.push(`/organisations/${res.id}`)
      modalClose()
    }
  }

  return (
    <Modal modalOpen={modalOpen} onModalClose={close}>
      <Content>
        <h3>Clone Organisation</h3>
        <InputField label="New name" value={name} onChange={setName} />
        <SelectField
          label="New product"
          options={productOptions}
          value={product}
          onChange={(option): void => {
            if (option) setProduct(option)
          }}
        />
      </Content>
      <ModalButtons>
        <Button buttonStyle={ButtonStyle.Secondary} onClick={close}>
          Cancel
        </Button>
        <AsyncButton
          buttonStyle={ButtonStyle.Action}
          onClick={clone}
          disabled={invalid}
        >
          Save
        </AsyncButton>
      </ModalButtons>
    </Modal>
  )
}
