import React, { ReactElement, useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  CardSize,
  enumKeyByValue,
  enumToOptions,
  getOverviewDateRange,
  IHistoricData,
  InputGroup,
  PeriodSelector,
  ReportDateRange,
  ReportMetric,
  ReportMetricType,
  SelectField,
  ReportingWidget,
  colors,
  Loading,
  renderTotal,
  CurrencyCode,
  ISelectOption,
  OrganisationFormat,
  getOverviewTotal,
  useDebounceFunction,
} from '@one-tree/library'
import moment from 'moment'
import styled from 'styled-components'
import { getReportingOverview } from '../../helpers/APIhelper'
import Graph from './Graph'
import { useOrganisation } from '../../context/OrganisationProvider'
import FadeIn from './FadeIn'
import { getOrgOptions } from '../organisations/OrgHelper'
import { productOptions } from '../../helpers/TableHelper'
import { metricExists } from '../../helpers/reportingHelper'
import { IOverviewFetchParams } from '../../types/APItypes'

const StyledReportingWidget = styled(ReportingWidget)`
  display: flex;
  font-size: 1.2rem;
  margin: 20px 0;
`
const Title = styled.span`
  font-weight: bold;
  margin-right: 7px;
`
const Separator = styled.span`
  border-right: 2px solid ${colors.darkGray};
  padding-left: 18px;
  margin-right: 18px;
`
const Value = styled.span`
  font-weight: bold;
  color: ${colors.lightBlue};
`
const StyledInputGroup = styled(InputGroup)`
  flex-flow: wrap;
  row-gap: 10px;
`

export default function Overview(): ReactElement | null {
  const [data, setData] = useState<IHistoricData[]>([])
  const [loading, setLoading] = useState(false)
  const { organisations } = useOrganisation()

  const orgOptions = getOrgOptions(organisations)
  const [organisation, setOrganisation] = useState<ISelectOption<number>>()

  const [product, setProduct] = useState<ISelectOption<OrganisationFormat>>()

  const [metric, setMetric] = useState(ReportMetric.Sales)
  const [metricType, setMetricType] = useState(ReportMetricType.Value)
  const [fromMonth, setFromMonth] = useState(moment().month() + 1)
  const [fromYear, setFromYear] = useState(moment().year() - 1)

  const [timeNow, setTimeNow] = useState<string>()

  const [commission, setCommission] = useState<number>()

  const [compare, setCompare] = useState(false)
  const [prevData, setPrevData] = useState<IHistoricData[]>()

  const fetchPrevData = async (params: IOverviewFetchParams): Promise<void> => {
    const res = await getReportingOverview(params)
    if (res) setPrevData(res.data)
  }

  const dateRange: ReportDateRange = {
    selected: getOverviewDateRange(fromMonth, fromYear),
    previous: getOverviewDateRange(fromMonth, fromYear - 1),
  }

  const fetchData = async (): Promise<void> => {
    setLoading(true)

    const fetchParams = {
      fromMonth,
      fromYear,
      format: (product && product.value) || undefined,
      organisationId: organisation?.value,
      metric,
    }

    const [res] = await Promise.all([
      getReportingOverview(fetchParams),
      compare
        ? fetchPrevData({ ...fetchParams, fromYear: fromYear - 1 })
        : null,
    ])

    if (res && res.data) {
      setData(res.data)
      setCommission(res.commission)
      setTimeNow(moment().format('HH:mm on DD/MM'))
    }

    setLoading(false)
  }

  const debounceFetch = useDebounceFunction(fetchData, 500)

  useEffect(() => {
    debounceFetch()
  }, [fromYear, fromMonth, product, organisation, metric, compare])

  useEffect(() => {
    if (organisation?.value || !product?.value) setProduct(undefined)
  }, [organisation, product])

  const notice = `Data is a snapshot${timeNow ? ` taken at ${timeNow}.` : '.'}`
  const totalCommission = commission && !loading ? `£${commission.toLocaleString()}` : <Loading />

  return (
    <Card cardSize={CardSize.Full}>
      <CardHeader title="Organisation overview" />
      <CardContent>
        <p>{notice}</p>
        <StyledInputGroup>
          <SelectField
            label="Select organisation"
            options={orgOptions}
            value={organisation}
            onChange={(option): void => {
              if (option) {
                setOrganisation(option)
              } else {
                setOrganisation(undefined)
              }
            }}
            isClearable={true}
          />
          <SelectField
            label="Select product"
            isClearable={true}
            options={productOptions}
            value={product}
            onChange={(option): void => {
              if (option) {
                setProduct(option)
              } else {
                setProduct(undefined)
              }
            }}
            disabled={Boolean(organisation?.value)}
          />
          <SelectField
            label="Status"
            options={enumToOptions(ReportMetric)}
            value={{
              value: metric,
              label: enumKeyByValue(ReportMetric, metric),
            }}
            onChange={(option): void => {
              if (option) setMetric(option.value)
            }}
          />
          <SelectField
            label="Data type"
            options={enumToOptions(ReportMetricType)}
            value={{
              value: metricType,
              label: enumKeyByValue(ReportMetricType, metricType),
            }}
            onChange={(option): void => {
              if (option) setMetricType(option.value)
            }}
          />
          <PeriodSelector
            fromMonth={fromMonth}
            setFromMonth={setFromMonth}
            fromYear={fromYear}
            setFromYear={setFromYear}
          />
        </StyledInputGroup>
        <StyledReportingWidget>
          <Title>{`Total ${metric} ${metricType} for ${dateRange.selected}: `}</Title>
          <Value>
            {!loading && metricExists(data, metric) ? (
              renderTotal(
                getOverviewTotal(data, metric, metricType),
                metricType,
                CurrencyCode.GBP,
              )
            ) : (
              <Loading />
            )}
          </Value>
          <Separator />
          <Title>{'Total income: '}</Title>
          <Value>{totalCommission}</Value>
        </StyledReportingWidget>
        <FadeIn ready={!loading} style={{ minHeight: '300px' }}>
          <Graph
            loading={loading}
            setLoading={setLoading}
            data={data}
            dateRange={dateRange}
            metric={metric}
            metricType={metricType}
            product={product}
            compare={compare}
            setCompare={setCompare}
            prevData={prevData}
          />
        </FadeIn>
      </CardContent>
    </Card>
  )
}
