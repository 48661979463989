import {
  Card, CardSize, CardTab, CardTabs,
} from '@one-tree/library'
import React, { ReactElement, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import Page from '../../components/Page'
import { Path } from '../../types/routes'
import InvoiceSettings from './InvoiceSettings'
import InvoiceTable from './InvoiceTable'

export default function Invoices(): ReactElement {
  const [changes, setChanges] = useState(false)

  return (
    <Page warnBeforeExit={changes}>
      <Card cardSize={CardSize.Full}>
        <CardTabs>
          <CardTab title="Invoices" to={Path.Invoices} />
          <CardTab title="Settings" to={Path.InvoiceSettings} />
        </CardTabs>

        <Switch>
          <Route
            exact={true}
            path={Path.Invoices}
            render={(): ReactElement => {
              if (changes) setChanges(false)
              return <InvoiceTable />
            }}
          />
          <Route exact={true} path={Path.InvoiceSettings}>
            <InvoiceSettings changes={changes} setChanges={setChanges} />
          </Route>
        </Switch>
      </Card>
    </Page>
  )
}
