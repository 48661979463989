import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react'
import { IAdminOrg, ITypes } from '../types/orgTypes'

interface IState {
  organisations: IAdminOrg[]
  saveOrganisations: (organisations: IAdminOrg[]) => void
  types: ITypes[]
  saveTypes: (types: ITypes[]) => void
}

const OrganisationContext = createContext<IState | undefined>(undefined)
const { Provider } = OrganisationContext

function OrganisationProvider({
  children,
}: {
  children: ReactNode
}): ReactElement {
  const [organisations, setOrganisations] = useState<IAdminOrg[]>(
    JSON.parse(localStorage.getItem('admin-orgs') || '[]'),
  )

  const [types, setTypes] = useState<ITypes[]>(
    JSON.parse(localStorage.getItem('admin-types') || '[]'),
  )

  const saveOrganisations = async (
    organisationsToSave: IAdminOrg[],
  ): Promise<void> => {
    if (!organisationsToSave) return
    setOrganisations(organisationsToSave)
    localStorage.setItem('admin-orgs', JSON.stringify(organisationsToSave))
  }

  const saveTypes = async (typesToSave: ITypes[]): Promise<void> => {
    if (!typesToSave) return
    setTypes(typesToSave)
    localStorage.setItem('admin-types', JSON.stringify(typesToSave))
  }

  const value = useMemo(
    () => ({
      organisations,
      saveOrganisations,
      types,
      saveTypes,
    }),
    [organisations, types],
  )

  return <Provider value={value}>{children}</Provider>
}

function useOrganisation(): IState {
  const context = useContext(OrganisationContext)
  if (context === undefined) {
    throw new Error(
      'useOrganisation must be used within an OrganisationProvider',
    )
  }
  return context
}

export { OrganisationProvider, useOrganisation }
