import { IFilters, Order, Table } from '@one-tree/library'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useOrganisation } from '../../context/OrganisationProvider'
import { getPending } from '../../helpers/APIhelper'
import { IAdminPending } from '../../types/adminTypes'
import { PaymentModalData } from '../../types/orgTypes'
import { Path } from '../../types/routes'
import { getOrgOptions } from '../organisations/OrgHelper'
import { getPendingColumns, PendingTableState } from './SalesHelper'

interface IPendingTableProps {
  resetTable: boolean
  setPaymentModal: (data: PaymentModalData) => void
  getState: (state: PendingTableState) => void
}
export default function PendingTable(props: IPendingTableProps): ReactElement {
  const { resetTable, setPaymentModal, getState } = props
  const { organisations } = useOrganisation()

  const history = useHistory()
  const navigate = (path: Path, id: number | string): void => {
    history.push(`${path}/${id.toString()}`, { backLink: Path.Pending })
  }

  const pendingColumns = getPendingColumns(organisations, navigate)

  const filters: IFilters<IAdminPending>[] = [
    {
      accessor: 'organisationId',
      options: getOrgOptions(organisations),
      placeholder: 'Select organisation...',
      slug: true,
    },
  ]

  return (
    <Table
      fetch={getPending}
      columns={pendingColumns}
      options={{ orderBy: 'date', orderDirection: Order.Desc }}
      search={true}
      filters={filters}
      dates={{
        startDate: moment().subtract(1, 'year').add(1, 'day').unix(),
        endDate: moment().unix(),
      }}
      reset={resetTable}
      rowLink={{
        onClick: ({ id, organisationId }) => setPaymentModal({ id, organisationId }),
      }}
      getState={getState}
    />
  )
}
