import {
  Button, ITableData, Table, useNotFirstRender,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { getUser } from '../../helpers/APIhelper'
import { IUserOrg, PartialUser } from '../../types/userTypes'
import { getUserOrgColumns } from '../users/UsersHelper'
import AddUserOrg from './AddUserOrg'
import EditUserOrg from './EditUserOrg'

const Styles = styled.div`
  margin-top: 40px;
`
interface IProps {
  user: PartialUser
}
export default function UserOrgs(props: IProps): ReactElement {
  const { user: initUser } = props
  const [editOrgModal, setEditOrgModal] = useState<number>()
  const [addOrgModal, setAddOrgModal] = useState<boolean>(false)
  const [reset, setReset] = useState(false)
  const [user, setUser] = useState<PartialUser>(initUser)

  const getThisUserOrgs = async (): Promise<ITableData<IUserOrg>> => {
    if (user.userId) {
      const res = await getUser({ id: user.userId.toString() })

      if (res) {
        setUser(res)

        const userOrgs = res?.organisations

        return {
          data: userOrgs || [],
          recordsTotal: userOrgs?.length || 0,
          recordsFiltered: userOrgs?.length || 0,
          limit: 1000,
          skip: 0,
        }
      }
    }

    return {
      data: [],
      recordsTotal: 0,
      recordsFiltered: 0,
      limit: 1000,
      skip: 0,
    }
  }

  const notFirstRender = useNotFirstRender()
  useEffect(() => {
    if (notFirstRender && !editOrgModal && !addOrgModal) {
      setReset((p) => !p)
    }
  }, [editOrgModal, addOrgModal])

  const editingOrg = user.organisations?.find(({ id }) => id === editOrgModal)

  const columns = getUserOrgColumns(setEditOrgModal)

  return (
    <Styles>
      <Table
        fetch={getThisUserOrgs}
        columns={columns}
        controls={(
          <Button onClick={(): void => setAddOrgModal(true)}>
            Add organisation
          </Button>
        )}
        reset={reset}
      />
      {editingOrg && (
        <EditUserOrg
          editOrgModal={editOrgModal}
          setEditOrgModal={setEditOrgModal}
          user={user}
          editingOrg={editingOrg}
        />
      )}
      <AddUserOrg
        addOrgModal={addOrgModal}
        setAddOrgModal={setAddOrgModal}
        user={user}
      />
    </Styles>
  )
}
