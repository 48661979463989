import { Animate } from '@one-tree/library'
import React, { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Page from '../../components/Page'
import LiveSales from './LiveSales'
import MiniGraphs from './MiniGraphs'
import Overview from './Overview'
import SalesWidget from './SalesWidget'

const TopRow = styled.div`
  display: flex;
  column-gap: 20px;
  max-width: 1800px;
  margin: 0 auto -20px;
`
export default function Reporting(): ReactElement {
  const { state } = useLocation()

  return (
    <Animate isAnimated={state === 'animate'}>
      <Page>
        <TopRow>
          <SalesWidget />
          <LiveSales />
        </TopRow>
        <MiniGraphs />
        <Overview />
      </Page>
    </Animate>
  )
}
