import React, { ReactNode } from 'react'
import {
  capitalize,
  formatCurrency,
  FormattedDateTime,
  IColumn,
  IFilters,
  RequestWithFilters,
} from '@one-tree/library'
import DisplayOrganisation from '../../components/DisplayOrganisation'
import { Path } from '../../types/routes'
import { IAdminOrg } from '../../types/orgTypes'
import { IAdminCards, IAdminPending } from '../../types/adminTypes'
import { ClickOrg } from '../../helpers/TableHelper'

export const getSalesColumns = (
  organisations: IAdminOrg[],
  navigate: (path: Path, id: number) => void,
): IColumn<IAdminCards>[] => {
  const columns: IColumn<IAdminCards>[] = [
    {
      accessor: 'id',
      header: 'Voucher ID',
    },
    {
      accessor: 'orderId',
      header: 'Order ID',
    },
    {
      accessor: 'organisationId',
      header: 'Organisation',
      sorting: false,
      display: ({ organisationId }): ReactNode => {
        const { name, format } = organisations.find(({ id }) => id === organisationId) || {}
        return (
          <ClickOrg
            onClick={(): void => navigate(Path.Organisations, organisationId)}
          >
            <DisplayOrganisation name={name} format={format} />
          </ClickOrg>
        )
      },
      clickable: false,
    },
    {
      accessor: 'purchaseDate',
      header: 'Purchase date',
      display: ({ purchaseDate }) => (
        <FormattedDateTime timestamp={purchaseDate} />
      ),
    },
    {
      accessor: 'purchaserLastName',
      header: 'Purchaser',
      display: (row) => `${row.purchaserFirstName} ${row.purchaserLastName}`,
    },
    {
      accessor: 'deliveryFormat',
      header: 'Method',
      display: ({ deliveryFormat }) => capitalize(deliveryFormat),
    },
    {
      accessor: 'value',
      header: 'Value',
      display: ({ value, organisationId }): ReactNode => {
        const thisOrg = organisations.find(({ id }) => organisationId === id)
        return formatCurrency(value, thisOrg?.currencyCode)
      },
    },
  ]
  return columns
}

export const getPendingColumns = (
  organisations: IAdminOrg[],
  navigate: (path: Path, id: number) => void,
): IColumn<IAdminPending>[] => {
  const columns: IColumn<IAdminPending>[] = [
    {
      accessor: 'id',
      header: 'Order ID',
    },
    {
      accessor: 'date',
      header: 'Purchase date',
      display: ({ date }) => <FormattedDateTime timestamp={date} />,
    },
    {
      accessor: 'orderTotal',
      header: 'Order total',
      display: ({ orderTotal, organisationId }): ReactNode => {
        const thisOrg = organisations.find(({ id }) => organisationId === id)
        return formatCurrency(orderTotal, thisOrg?.currencyCode) || 0
      },
    },
    {
      accessor: 'purchaser',
      header: 'Purchaser',
    },
    {
      accessor: 'organisationId',
      header: 'Organisation',
      sorting: false,
      display: ({ organisationId }): ReactNode => {
        const { name, format } = organisations.find(({ id }) => id === organisationId) || {}
        return (
          <ClickOrg
            onClick={(): void => navigate(Path.Organisations, organisationId)}
          >
            <DisplayOrganisation name={name} format={format} />
          </ClickOrg>
        )
      },
      clickable: false,
    },
    {
      accessor: 'orderItems',
      header: 'Order items',
      display: ({ orderItems }): ReactNode => orderItems.map((item, index) => {
        // TODO is this really the best way? Shall we just remove that linting rule?
        const key = `${index}`

        const dot = '\u2022 '
        return (
          <div key={key}>
            {dot}
            {item}
          </div>
        )
      }),
      sorting: false,
    },
  ]
  return columns
}

export type PendingTableState = RequestWithFilters<
  IAdminPending,
  IFilters<IAdminPending>[]
>
