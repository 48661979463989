import {
  AsyncButton,
  Button,
  ButtonStyle,
  CardContent,
  CardHeader,
  IFilters,
  ISelectOption,
  stringsToOptions,
  Table,
} from '@one-tree/library'
import download from 'downloadjs'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useOrganisation } from '../../context/OrganisationProvider'
import {
  getPaginatedOrganisations,
  exportOrganisations,
} from '../../helpers/APIhelper'
import { fetchGroups } from '../../helpers/groupHelper'
import { productOptions } from '../../helpers/TableHelper'
import { IAdminOrg } from '../../types/orgTypes'
import { Path } from '../../types/routes'
import {
  getOrgColumns,
  getTypeOptions,
  OrganisationTableState,
} from './OrgHelper'

const Controls = styled.div`
  display: flex;
`

export default function OrgTable(): ReactElement {
  const history = useHistory()
  const navigate = (
    path: Path,
    id?: string | number,
    usersFilterOrg?: number,
  ): void => {
    history.push(`${path}/${id || ''}`, { orgId: usersFilterOrg })
  }

  const { types } = useOrganisation()
  const orgColumns = getOrgColumns(navigate, types)

  const [groupOptions, setGroupOptions] = useState<ISelectOption<number>[]>([])
  const groups = async (): Promise<void> => setGroupOptions(await fetchGroups())
  useEffect(() => {
    groups()
  }, [])

  const filters: IFilters<IAdminOrg>[] = [
    {
      accessor: 'status',
      options: stringsToOptions(['Active', 'Suspended', 'Cancelled', 'Draft']),
      placeholder: 'Select status...',
      value: 'Active',
    },
    {
      accessor: 'type',
      options: getTypeOptions(types),
      placeholder: 'Select type...',
    },
    {
      accessor: 'format',
      options: productOptions,
      placeholder: 'Select product...',
    },
  ]

  const [tableState, setTableState] = useState<OrganisationTableState>()
  const exportCsv = async (): Promise<void> => {
    const res = await exportOrganisations({ ...tableState })
    if (res) download(res, 'Organisations.csv')
  }

  return (
    <>
      <CardHeader title="All organisations">
        {/* TODO these controls Super Admin only */}
        <Controls>
          <AsyncButton onClick={exportCsv}>Export CSV</AsyncButton>
          <Button
            buttonStyle={ButtonStyle.Action}
            onClick={(): void => navigate(Path.Organisations, 'create')}
          >
            Create new organisation
          </Button>
        </Controls>
      </CardHeader>
      <CardContent>
        <Table
          fetch={getPaginatedOrganisations}
          columns={orgColumns}
          search={true}
          filters={filters}
          rowLink={{ route: Path.Masquerade, slug: 'id' }}
          getState={setTableState}
          options={{ orderBy: 'name' }}
          customFilter={{
            accessor: 'group',
            options: groupOptions,
            placeholder: 'Select group...',
          }}
        />
      </CardContent>
    </>
  )
}
