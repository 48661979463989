import {
  alertSuccess,
  AsyncButton,
  Button,
  ButtonStyle,
  CardContent,
  CardError,
  CardHeader,
  CurrencyCode,
  CurrencyField,
  ImageType,
  InputField,
  Loading,
  TextArea,
  UploadImageField,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  getGlobalSettings,
  patchGlobalSettings,
  uploadImage,
} from '../../helpers/APIhelper'
import { IGlobalSettings } from '../../types/APItypes'
import { Path } from '../../types/routes'
import { getSettingsErrors, isSettingsValid } from './settingValidation'

const Styles = styled.div`
  display: grid;
  row-gap: 20px;
  max-width: 500px;

  textarea.styled-input {
    max-width: 600px;
    min-height: 100px;
  }
  h3 {
    margin-bottom: 0;
  }
`
interface IOrgSettingsProps {
  hasChanges: boolean
  setHasChanges: (value: boolean) => void
}
export default function OrgSettings({
  hasChanges,
  setHasChanges,
}: IOrgSettingsProps): ReactElement {
  const [loading, setLoading] = useState(true)
  const [settings, setSettings] = useState<Partial<IGlobalSettings>>({})
  const [changes, setChanges] = useState<Partial<IGlobalSettings>>({})
  const data = { ...settings, ...changes }

  const invalid = !isSettingsValid(data)
  const errors = getSettingsErrors(data)

  const fetchData = async (): Promise<void> => {
    const res = await getGlobalSettings()
    if (res) setSettings(res)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const update = <T extends unknown>(
    value: T,
    key: keyof IGlobalSettings,
  ): void => {
    setChanges((prevState) => ({
      ...prevState,
      [key]: value,
    }))
    setHasChanges(true)
  }

  const updateSplashImage = (
    image: { id: number; url: string },
    idKey: keyof IGlobalSettings,
    urlKey: keyof IGlobalSettings,
  ): void => {
    setChanges((prevState) => ({
      ...prevState,
      [idKey]: image.id,
      [urlKey]: image.url,
    }))
    setHasChanges(true)
  }

  const render = !data ? (
    'No data'
  ) : (
    <Styles>
      <CurrencyField
        style={{ maxWidth: '150px' }}
        label="First class delivery price (inc. VAT)"
        initValue={
          data.firstClassDelivery ? data.firstClassDelivery.toString() : ''
        }
        currency={CurrencyCode.GBP}
        onChange={(value: string): void => update(parseFloat(value), 'firstClassDelivery')}
      />
      <TextArea
        label="Purchaser first class delivery information"
        value={data.firstClassDeliveryInfo}
        onChange={(value: string): void => update(value, 'firstClassDeliveryInfo')}
      />
      <TextArea
        label="Purchaser postage message"
        value={data.postageMessage}
        onChange={(value: string): void => update(value, 'postageMessage')}
      />
      <TextArea
        label="Hosted page delivery information"
        value={data.hostedDeliveryInformation}
        onChange={(value: string): void => update(value, 'hostedDeliveryInformation')}
      />
      <TextArea
        label="Hosted page email voucher terms"
        value={data.hostedEmailDeliveryTerms}
        onChange={(value: string): void => update(value, 'hostedEmailDeliveryTerms')}
        rows={7}
      />
      <TextArea
        label="Hosted page postal voucher terms"
        value={data.hostedPostDeliveryTerms}
        onChange={(value: string): void => update(value, 'hostedPostDeliveryTerms')}
        rows={7}
      />
      <h3>Splash screen</h3>
      <InputField
        style={{ maxWidth: '350px' }}
        label="Splash link"
        value={data.splashLink}
        onChange={(value: string): void => update(value, 'splashLink')}
      />
      <UploadImageField
        label="Splash foreground image"
        imageType={ImageType.SplashForeground}
        previewUrl={data.splashForegroundUrl}
        subtitle="This should be a landscape png (or high-quality jpg) with information on it, no wider than 600px."
        upload={uploadImage}
        onSuccess={(response): void => {
          updateSplashImage(
            response,
            'splashForegroundId',
            'splashForegroundUrl',
          )
        }}
      />
      <UploadImageField
        label="Splash background image"
        imageType={ImageType.SplashBackground}
        previewUrl={data.splashBackgroundUrl}
        subtitle="This should be a landscape jpg image between 1000px and 2000px wide. Aim for the highest size possible without going over 1mb (~1000kb)"
        upload={uploadImage}
        onSuccess={(response): void => {
          updateSplashImage(
            response,
            'splashBackgroundId',
            'splashBackgroundUrl',
          )
        }}
      />
    </Styles>
  )

  const handleSave = async (): Promise<void> => {
    if (!changes) return
    const res = await patchGlobalSettings(changes)
    if (res) {
      setSettings(res)
      setChanges({})
      setHasChanges(false)
      alertSuccess('Settings saved')
    }
  }

  const history = useHistory()

  return (
    <>
      <CardHeader title="Global organisation settings">
        <Button
          buttonStyle={ButtonStyle.Secondary}
          onClick={(): void => history.push(Path.Organisations)}
        >
          Cancel
        </Button>
        <AsyncButton
          buttonStyle={ButtonStyle.Action}
          onClick={handleSave}
          disabled={!hasChanges || invalid}
        >
          Save
        </AsyncButton>
      </CardHeader>
      <CardError>{errors[0]}</CardError>
      <CardContent>
        {loading ? <Loading fullPage={true} /> : render}
      </CardContent>
    </>
  )
}
