import { IGlobalSettings } from '../../types/APItypes'

export const deliveryPriceTest = (
  settings: Partial<IGlobalSettings>,
): string | false => {
  if (!settings.firstClassDelivery) return 'Delivery price required'
  return false
}

export const deliveryInfoTest = (
  settings: Partial<IGlobalSettings>,
): string | false => {
  if (!settings.firstClassDeliveryInfo) return 'Delivery info required'
  return false
}

export const postageMessageTest = (
  settings: Partial<IGlobalSettings>,
): string | false => {
  if (!settings.postageMessage) return 'Postage message required'
  return false
}

export const getSettingsErrors = (
  settings: Partial<IGlobalSettings>,
): string[] => {
  const errorArray: string[] = []

  const add = (
    errorTest: (settings: Partial<IGlobalSettings>) => string | false,
  ): void => {
    if (!settings) errorArray.push('Can\'t find settings')

    const error = errorTest(settings)
    if (error) errorArray.push(error)
  }

  add(deliveryPriceTest)
  add(deliveryInfoTest)
  add(postageMessageTest)

  return errorArray
}

export const isSettingsValid = (
  settings: Partial<IGlobalSettings>,
): boolean => {
  if (getSettingsErrors(settings).length) return false
  return true
}
