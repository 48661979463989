import { alertError, Loading } from '@one-tree/library'
import React, { ReactElement, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { getToken } from '../helpers/APIhelper'

export default function Masquerade(): ReactElement {
  const { id }: { id: string } = useParams()
  const { state }: { state: { voucherId?: string } } = useLocation()

  const history = useHistory()

  const voucher = state?.voucherId ? `&voucherId=${state.voucherId}` : ''

  useEffect(() => {
    (async (): Promise<void> => {
      const { token } = (await getToken({ id })) || {}

      if (token) {
        window.open(
          `${process.env.REACT_APP_MASQUERADE_URL}/login?token=${token}${voucher}`,
          '_blank',
        )
        history.goBack()
      } else {
        alertError('Error getting masquerade token')
      }
    })()
  }, [])

  return <Loading fullPage={true} />
}
