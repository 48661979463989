import { capitalize, colors } from '@one-tree/library'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { OrgStatus } from '../types/orgTypes'

const Styles = styled.div`
  color: ${({ theme: { color } }): string => color};
`

interface IDisplayStatusProps {
  status?: OrgStatus
}
export default function DisplayStatus(
  props: IDisplayStatusProps,
): ReactElement {
  const { status } = props

  const color = (): string => {
    switch (status) {
    case OrgStatus.Active:
      return colors.green
    case OrgStatus.Suspended:
      return colors.red
    case OrgStatus.Cancelled:
      return colors.black
    case OrgStatus.Draft:
      return colors.blue
    default:
      return colors.orange
    }
  }

  return <Styles theme={{ color }}>{capitalize(status)}</Styles>
}
