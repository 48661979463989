import { formatCurrency, IHistoricData, ReportMetric } from '@one-tree/library'
import { RankingsItem, RankingsMetricType } from '../types/APItypes'

export const getRankingValue = (
  item: RankingsItem,
  type: 'performance' | 'ranking',
  metric: RankingsMetricType,
): string => {
  const isValue = metric !== RankingsMetricType.Volume

  if (type === 'performance') {
    return isValue ? formatCurrency(item.value) || '£0' : item.value.toString()
  }
  return `${item.value}%`
}

export const metricExists = (
  data: IHistoricData[] | undefined,
  metric: ReportMetric,
): boolean => {
  if (!data || !data.length) return false
  return data.some((item) => metric in item)
}
