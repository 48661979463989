import {
  alertSuccess,
  Button,
  ButtonStyle,
  CardContent,
  CardHeader,
  Table,
  useModal,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import { deleteGroup, getGroups } from '../../helpers/APIhelper'
import { IGroup } from '../../types/orgTypes'
import EditOrgGroups from './EditOrgGroups'
import { getGroupColumns } from './OrgHelper'

export default function OrgGroups(): ReactElement {
  const [group, setGroup] = useState<IGroup>()
  const [modal, close, open] = useModal()
  const [resetTable, setResetTable] = useState(false)
  const reset = (): void => setResetTable((p) => !p)

  const handleDeleteGroup = async (id: number): Promise<void> => {
    const res = await deleteGroup({ id })
    if (res) alertSuccess('Group deleted')
    reset()
  }

  const groupColumns = getGroupColumns(setGroup, handleDeleteGroup)

  useEffect(() => {
    if (!modal) {
      setGroup(undefined)
      reset()
    }
  }, [modal])

  useEffect(() => {
    if (group) open()
  }, [group])

  return (
    <>
      <CardHeader title="Organisation groups">
        <Button buttonStyle={ButtonStyle.Action} onClick={open}>
          Create new group
        </Button>
      </CardHeader>
      <CardContent>
        <Table fetch={getGroups} columns={groupColumns} reset={resetTable} />
      </CardContent>
      {modal ? (
        <EditOrgGroups group={group} useModal={[modal, close, open]} />
      ) : null}
    </>
  )
}
