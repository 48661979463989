import React, { ReactNode } from 'react'
import {
  AsyncButton,
  Button,
  ButtonSize,
  ButtonStyle,
  IColumn,
  IFilters,
  InputGroup,
  ISelectOption,
  RequestWithFilters,
  StateAction,
  toSentenceCase,
  transformDate,
} from '@one-tree/library'
import styled from 'styled-components'
import DisplayOrganisation from '../../components/DisplayOrganisation'
import DisplayStatus from '../../components/DisplayStatus'
import { Path } from '../../types/routes'
import { IAdminOrg, IGroup, ITypes } from '../../types/orgTypes'
import { ClickOrg } from '../../helpers/TableHelper'

export const getType = (types: ITypes[], type: string): string => {
  const thisType = types.find(({ id }) => id === Number(type))
  return thisType?.name ? toSentenceCase(thisType.name) : '-'
}

export const getTypeOptions = (types: ITypes[]): ISelectOption<number>[] => {
  const options = types.map(({ id, name }) => ({
    value: id,
    label: toSentenceCase(name),
  }))
  return options
}

export const getOrgColumns = (
  navigate: (path: Path, id?: number, usersFilterOrg?: number) => void,
  types: ITypes[],
): IColumn<IAdminOrg>[] => {
  const columns: IColumn<IAdminOrg>[] = [
    {
      accessor: 'name',
      header: 'Organisation',
      display: ({ id, name, format }): ReactNode => (
        <ClickOrg onClick={(): void => navigate(Path.Organisations, id)}>
          <DisplayOrganisation name={name} format={format} />
        </ClickOrg>
      ),
      clickable: false,
    },
    {
      accessor: 'status',
      header: 'Status',
      display: ({ status }): ReactNode => <DisplayStatus status={status} />,
    },
    {
      accessor: 'type',
      header: 'Type',
      display: ({ type }): string => getType(types, type),
    },
    {
      accessor: 'group',
      header: 'Group',
    },
    {
      accessor: 'notes',
      header: 'Notes',
    },
    {
      accessor: 'createdOn',
      header: 'Created',
      display: ({ createdOn }): string => transformDate(createdOn),
    },
    {
      accessor: 'code',
      header: 'Code',
    },
    {
      sorting: false,
      clickable: false,
      display: ({ id }): ReactNode => (
        <Button
          onClick={(): void => navigate(Path.Users, undefined, id)}
          buttonStyle={ButtonStyle.Secondary}
          buttonSize={ButtonSize.Table}
        >
          Users
        </Button>
      ),
    },
  ]
  return columns
}

export const getOrgOptions = (
  organisations: IAdminOrg[],
): ISelectOption<number>[] => {
  const orgs = organisations.sort((a, b) => {
    if (!a.name || !b.name) return 0
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })
  const orgOptions: ISelectOption<number>[] = orgs.map((org) => ({
    value: org.id,
    label: org.name,
  }))
  return orgOptions
}

const Name = styled.div`
  white-space: nowrap;
`
export const getGroupColumns = (
  setGroup: StateAction<IGroup | undefined>,
  deleteGroup: (id: number) => Promise<void>,
): IColumn<IGroup>[] => {
  const columns: IColumn<IGroup>[] = [
    {
      accessor: 'id',
      header: 'ID',
    },
    {
      accessor: 'name',
      header: 'Name',
      display: ({ name }): ReactNode => <Name>{name}</Name>,
    },
    {
      accessor: 'organisations',
      header: 'Organisations',
      display: ({ organisations }): ReactNode => {
        const maxSeven = organisations.slice(0, 7)
        const others = organisations.length - 7
        let displayText = maxSeven.map((org) => org.name).join(', ')
        if (others > 0) displayText += ` and ${others} more...`
        return displayText
      },
    },
    {
      sorting: false,
      clickable: false,
      display: (group): ReactNode => (
        <InputGroup>
          <Button
            onClick={(): void => setGroup(group)}
            buttonStyle={ButtonStyle.Secondary}
            buttonSize={ButtonSize.Table}
          >
            Edit
          </Button>
          <AsyncButton
            onClick={(): Promise<void> => deleteGroup(group.id)}
            buttonStyle={ButtonStyle.Danger}
            buttonSize={ButtonSize.Table}
            showConfirmation={true}
          >
            Delete
          </AsyncButton>
        </InputGroup>
      ),
    },
  ]
  return columns
}

export type OrganisationTableState = RequestWithFilters<
  IAdminOrg,
  IFilters<IAdminOrg>[]
>
