import {
  CurrencyCode,
  DeliveryFormat,
  OrganisationFormat,
} from '@one-tree/library'
import { PaymentMethod, Size } from './orgTypes'

export interface IAdminCards {
  organisationId: number
  format: OrganisationFormat
  size: Size
  deliveryFirstName: string
  deliveryLastName: string
  giftItemName: string
  id: number
  orderId: number
  purchaseDate: number
  purchaserFirstName: string
  purchaserLastName: string
  startDate: number
  status: string
  value: number
  deliveryFormat: DeliveryFormat
}

export interface IDispatch {
  id: number
  organisationId: number
  organisationName: string
  deliveryName: string
  dispatchDate: number
  purchaseDate: number
  size: Size
  exported: boolean
  dispatched: boolean
}

export interface IAdminPending {
  date: number
  id: number
  orderItems: string[]
  orderTotal: number
  organisationId: number
  organisationName: string
  purchaser: string
}

export enum InvoiceStatus {
  Open = 'open',
  Cancelled = 'cancelled',
  Paid = 'paid',
  PaidGoCardless = 'paid-gocardless',
}
export interface IInvoiceItem {
  id?: number
  description: string
  value: number
  type?: string
  date?: number
  url?: string
}
export interface IAddress {
  name: string
  addressLineOne: string
  addressLineTwo: string
  addressTown: string
  addressCounty: string
  addressPostcode: string
}
export interface IInvoice {
  id: number
  organisationId: number
  subtotal: number
  total: number
  date: number
  dueDate: number
  paidDate: number
  status: InvoiceStatus
  paymentMethod: PaymentMethod
  name: string
  items: IInvoiceItem[]
  currencyCode: CurrencyCode
  address: Partial<IAddress>
  pdfUrl: string
}
export interface IInvoiceAddition {
  description: string
  amount: number
  timestamp?: number
}
