import {
  CardContent,
  CardHeader,
  DeliveryFormat,
  enumToOptions,
  IFilters,
  Order,
  Table,
} from '@one-tree/library'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useOrganisation } from '../../context/OrganisationProvider'
import { getSales } from '../../helpers/APIhelper'
import { productOptions } from '../../helpers/TableHelper'
import { IAdminCards } from '../../types/adminTypes'
import { Path } from '../../types/routes'
import { getOrgOptions } from '../organisations/OrgHelper'
import { getSalesColumns } from './SalesHelper'

export default function SalesTable(): ReactElement {
  const { organisations } = useOrganisation()
  const history = useHistory()

  const navigate = (path: Path, id: number): void => {
    history.push(`${path}/${id.toString()}`, { backLink: Path.Sales })
  }

  const salesColumns = getSalesColumns(organisations, navigate)

  const orgOptions = getOrgOptions(organisations)
  const orgId = Number(history.location.search.slice(1)) || undefined

  const filters: IFilters<IAdminCards>[] = [
    {
      accessor: 'format',
      options: productOptions,
      placeholder: 'Select product...',
    },
    {
      accessor: 'organisationId',
      options: orgOptions,
      value: orgId,
      placeholder: 'Select organisation...',
      slug: true,
    },
    {
      accessor: 'organisationId',
      options: [],
      placeholder: 'Select group...',
    },
    {
      accessor: 'deliveryFormat',
      options: enumToOptions(DeliveryFormat),
      placeholder: 'Select method...',
    },
  ]

  return (
    <>
      <CardHeader title="Live vouchers" />
      <CardContent>
        <Table
          fetch={getSales}
          columns={salesColumns}
          options={{
            orderBy: 'purchaseDate',
            orderDirection: Order.Desc,
          }}
          search={true}
          filters={filters}
          dates={{
            startDate: moment().subtract(1, 'year').add(1, 'day').unix(),
            endDate: moment().unix(),
          }}
          rowLink={{
            onClick: (row): void => {
              history.push(`${Path.Masquerade}/${row.organisationId}`, {
                voucherId: row.id,
              })
            },
          }}
        />
      </CardContent>
    </>
  )
}
