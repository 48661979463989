import { AsyncButton, CardContent, CardHeader } from '@one-tree/library'
import download from 'downloadjs'
import React, { ReactElement, useState } from 'react'
import { PaymentModalData } from '../../types/orgTypes'
import PaymentModal from './PaymentModal'
import PendingTable from './PendingTable'
import { PendingTableState } from './SalesHelper'
import { exportPending } from '../../helpers/APIhelper'

export default function Pending(): ReactElement {
  const [paymentModal, setPaymentModal] = useState<PaymentModalData>()
  const [resetTable, setResetTable] = useState<boolean>(false)

  const [tableState, setTableState] = useState<PendingTableState>()
  const exportCsv = async (): Promise<void> => {
    const res = await exportPending({ ...tableState })
    if (res) download(res, 'Pending.csv')
  }

  return (
    <>
      <CardHeader title="Pending payments">
        <AsyncButton onClick={exportCsv}>Export CSV</AsyncButton>
      </CardHeader>
      <CardContent>
        <PendingTable
          resetTable={resetTable}
          setPaymentModal={setPaymentModal}
          getState={setTableState}
        />
      </CardContent>
      <PaymentModal
        paymentModal={paymentModal}
        setPaymentModal={setPaymentModal}
        resetTable={(): void => setResetTable((prevState) => !prevState)}
      />
    </>
  )
}
