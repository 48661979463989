import {
  alertSuccess,
  AsyncButton,
  Button,
  ButtonStyle,
  Modal,
  TextArea,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useOrganisation } from '../../context/OrganisationProvider'
import { addPendingPayment } from '../../helpers/APIhelper'
import { PaymentModalData } from '../../types/orgTypes'

const ModalContents = styled.div`
  h2 {
    margin-top: 0;
  }
  min-width: 300px;
`
const ModalButtons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`
interface IPaymentModalProps {
  paymentModal: PaymentModalData
  setPaymentModal: (data: PaymentModalData) => void
  resetTable: () => void
}
export default function PaymentModal(props: IPaymentModalProps): ReactElement {
  const { paymentModal, setPaymentModal, resetTable } = props
  const { organisations } = useOrganisation()
  const org = organisations.find(
    ({ id }) => id === paymentModal?.organisationId,
  )
  const [paymentReference, setPaymentReference] = useState<string>()

  useEffect(() => {
    setPaymentReference(
      org && paymentModal ? `Manual : ${org.code}_${paymentModal.id}` : '',
    )
  }, [paymentModal])

  const addPayment = async (): Promise<void> => {
    if (paymentReference && paymentModal) {
      const res = await addPendingPayment({
        voucherId: paymentModal.id,
        paymentReference,
      })
      if (res) alertSuccess('Payment added')
      resetTable()
      setPaymentModal(undefined)
    }
  }

  return (
    <Modal
      onModalClose={(): void => {
        setPaymentModal(undefined)
        setPaymentReference('')
      }}
      modalOpen={Boolean(paymentModal)}
    >
      <ModalContents>
        <h2>Add payment</h2>
        <TextArea
          placeholder="Add payment reference..."
          value={paymentReference}
          onChange={setPaymentReference}
          rows={3}
        />
        <ModalButtons>
          <Button
            buttonStyle={ButtonStyle.Secondary}
            onClick={(): void => {
              setPaymentModal(undefined)
              setPaymentReference('')
            }}
          >
            Cancel
          </Button>
          <AsyncButton
            buttonStyle={ButtonStyle.Action}
            onClick={addPayment}
            disabled={!paymentReference}
          >
            Save
          </AsyncButton>
        </ModalButtons>
      </ModalContents>
    </Modal>
  )
}
