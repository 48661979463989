import {
  colors, LinkSection, MenuLink, Role, hasRole,
} from '@one-tree/library'
import React, { ReactElement } from 'react'
import {
  FiAward,
  FiLogOut,
  FiPieChart,
  FiSend,
  FiTrendingUp,
  FiUsers,
} from 'react-icons/fi'
import { TbBuildingWarehouse, TbCoins } from 'react-icons/tb'
import { useAuth } from '../context/AuthProvider'
import { Path } from '../types/routes'

function SideMenu(): ReactElement {
  const { user } = useAuth()
  const { role } = user || {}

  const highlight = {
    primaryColor: colors.green,
    highlightColor: colors.lightestGreen,
  }

  return (
    <>
      <LinkSection>
        <MenuLink
          to={Path.Reporting}
          icon={<FiPieChart />}
          label="Reporting"
          hasPermission={hasRole(role, Role.SuperAdmin)}
          {...highlight}
        />
        <MenuLink
          to={Path.Sales}
          icon={<FiTrendingUp />}
          label="Sales"
          hasPermission={hasRole(role, Role.SubSuperAdmin)}
          {...highlight}
          notExact={true}
        />
        <MenuLink
          to={Path.Organisations}
          icon={<TbBuildingWarehouse />}
          label="Organisations"
          hasPermission={hasRole(role, Role.SubSuperAdmin)}
          {...highlight}
          notExact={true}
        />
        <MenuLink
          to={Path.Performance}
          icon={<FiAward />}
          label="Performance"
          hasPermission={hasRole(role, Role.SubSuperAdmin)}
          {...highlight}
        />
        <MenuLink
          to={Path.Invoices}
          icon={<TbCoins />}
          label="Invoices"
          hasPermission={hasRole(role, Role.SuperAdmin)}
          {...highlight}
          notExact={true}
        />
        <MenuLink
          to={Path.Users}
          icon={<FiUsers />}
          label="Users"
          hasPermission={hasRole(role, Role.SubSuperAdmin)}
          {...highlight}
          notExact={true}
        />
        {/* TODO why logout when user has Dispatch role? */}
        <MenuLink
          to={Path.Dispatch}
          icon={<FiSend />}
          label="Dispatch"
          hasPermission={hasRole(role, Role.Dispatch)}
          {...highlight}
        />
      </LinkSection>
      <LinkSection style={{ marginTop: 'auto' }}>
        <MenuLink
          to={Path.Logout}
          icon={<FiLogOut style={{ transform: 'rotate(180deg)' }} />}
          label="Logout"
          hasPermission={true}
          {...highlight}
        />
      </LinkSection>
    </>
  )
}
export default SideMenu
