import {
  Buttonlink,
  Checkbox,
  colors,
  IColumn,
  OrganisationFormat,
  transformDate,
  TransformDateFormat,
} from '@one-tree/library'
import React, { ReactNode } from 'react'
import { HiCheckCircle } from 'react-icons/hi'
import styled from 'styled-components'
import DisplayOrganisation from '../../components/DisplayOrganisation'
import { IDispatch } from '../../types/adminTypes'

const Check = styled(HiCheckCircle)`
  width: 22px;
  margin: -1px -3px -5px;
  height: auto;
  color: ${colors.green};
`

export const getDispatchColumns = (
  selected: number[],
  select: (id: number) => void,
  selectAll: () => void,
  allSelected: boolean,
): IColumn<IDispatch>[] => {
  const columns: IColumn<IDispatch>[] = [
    {
      accessor: 'organisationId',
      header: 'Organisation',
      display: ({ organisationName }): ReactNode => (
        <DisplayOrganisation
          name={organisationName}
          // only gift vouchers are dispatched
          format={OrganisationFormat.GiftVouchers}
        />
      ),
    },
    {
      accessor: 'id',
      header: 'Voucher ID',
    },
    {
      accessor: 'purchaseDate',
      header: 'Purchase date',
      display: ({ purchaseDate }): string => {
        const date = transformDate(
          purchaseDate,
          TransformDateFormat.NumbersAndSlashes,
        )
        return date
      },
    },
    {
      accessor: 'dispatchDate',
      header: 'Dispatch date',
      display: ({ dispatchDate }): string => {
        const date = dispatchDate
          ? transformDate(dispatchDate, TransformDateFormat.NumbersAndSlashes)
          : '-'
        return date
      },
    },
    {
      accessor: 'size',
      header: 'Size',
    },
    {
      accessor: 'deliveryName',
      header: 'Name',
    },
    {
      accessor: 'exported',
      header: 'Exported',
      display: ({ exported }): ReactNode => (exported ? <Check /> : ''),
    },
    {
      header: (
        <Buttonlink
          onClick={selectAll}
          style={{ minWidth: '75px', display: 'block' }}
        >
          {allSelected ? 'Deselect page' : 'Select page'}
        </Buttonlink>
      ),
      sorting: false,
      display: ({ id }) => (
        <Checkbox
          label=""
          onChange={(): void => select(id)}
          value={selected.includes(id)}
        />
      ),
    },
  ]
  return columns
}

export const isNumber = (number: string): boolean => {
  if (!Number.isInteger(Number(number))) return false
  return true
}

export const isId = (id: string): boolean => {
  if (id.length < 6 || id.length > 7) return false
  if (!isNumber(id)) return false
  return true
}
