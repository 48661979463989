import {
  CardContent,
  colors,
  enumToOptions,
  enumToRadioOptions,
  InputField,
  InputGroup,
  ISelectOption,
  OrganisationFormat,
  Radio,
  SelectField,
  Switch,
  TextArea,
  transformDate,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useOrganisation } from '../../context/OrganisationProvider'
import { fetchGroups } from '../../helpers/groupHelper'
import { OrgStatus, Size } from '../../types/orgTypes'
import { getType, getTypeOptions } from '../organisations/OrgHelper'
import {
  Column, IEditOrgProps, Main, productMap, statusMap,
} from './OrgHelper'
import { activateError, isOrgValid } from './OrgValidation'

const Settings = styled.div`
  h3 {
    margin: 0;
  }
  border: 1px solid ${colors.lightGray};
  border-radius: 4px;
  background-color: ${colors.lightestGray};
  padding: 20px;
  display: grid;
  grid-row-gap: 20px;
`
export default function OrgDetails(props: IEditOrgProps): ReactElement {
  const {
    organisation,
    organisation: {
      code,
      createdOn,
      displayName,
      format,
      groupId,
      lastActivated,
      name,
      notes,
      registration,
      status,
      vat,
      hasIndividuals,
      categoriesEnabled,
      categoryImages,
      size,
      wallet,
      customIframeCss,
      invoiceName,
      type,
    },
    change,
  } = props

  const created = createdOn
    ? `Organisation created on ${transformDate(createdOn)}. `
    : 'New organisation. '
  const activated = lastActivated
    ? `Last activated on ${transformDate(lastActivated)}.`
    : 'Never activated.'

  const isGiftVouchers = format === OrganisationFormat.GiftVouchers

  const invalid = !isOrgValid(organisation)

  const { types } = useOrganisation()

  const [groupOptions, setGroupOptions] = useState<ISelectOption<number>[]>([])
  const groups = async (): Promise<void> => setGroupOptions(await fetchGroups())
  useEffect(() => {
    groups()
  }, [])

  const isGiftVoucher = format === OrganisationFormat.GiftVouchers
  const creatingNewOrg = !organisation.id

  return (
    <CardContent>
      <Settings>
        <h3>Settings</h3>
        {created}
        {activated}
        <InputGroup>
          <SelectField
            placeholder="Select status..."
            label="Status"
            options={enumToOptions(OrgStatus)}
            value={{ value: status, label: status && statusMap.get(status) }}
            onChange={(option): void => change({ status: option?.value })}
            disabled={invalid}
            isError={invalid || activateError(organisation)}
          />
          <SelectField
            placeholder="Select group..."
            label="Group"
            options={groupOptions}
            value={
              groupId
                ? {
                  value: groupId,
                  label: groupOptions.find((o) => o.value === groupId)?.label,
                }
                : undefined
            }
            onChange={(option): void => {
              if (option && option.value) {
                change({ groupId: option.value })
              } else {
                change({ groupId: null })
              }
            }}
            isClearable={true}
            clearLabel="No group"
          />
          <InputField
            placeholder="Add notes..."
            label="Notes"
            value={notes}
            onChange={(value): void => change({ notes: value })}
          />
        </InputGroup>
      </Settings>
      <Main>
        <Column>
          <h3>Details</h3>
          <InputField
            placeholder="Add name..."
            label="Organisation name"
            maxChars={45}
            value={name}
            onChange={(value): void => change({ name: value })}
          />
          <InputField
            placeholder="Add name..."
            label="Display name"
            maxChars={45}
            value={displayName}
            onChange={(value): void => change({ displayName: value })}
          />
          <InputField
            placeholder={name || 'Add name...'}
            label="Invoice name"
            maxChars={80}
            value={invoiceName}
            onChange={(value): void => change({ invoiceName: value })}
          />
          {!creatingNewOrg && (
            <InputField
              placeholder="Add code..."
              label="Code"
              maxChars={10}
              subtitle="Customise if preferred"
              value={code}
              onChange={(value): void => change({ code: value })}
            />
          )}
          <InputField
            placeholder="VAT number..."
            label="VAT number"
            value={vat}
            onChange={(value): void => change({ vat: value })}
          />
          <InputField
            placeholder="Registration number..."
            label="Registration number"
            value={registration}
            onChange={(value): void => change({ registration: value })}
          />
          <h3>Custom CSS</h3>
          <TextArea
            subtitle="Use CSS to change elements of the page's styling, e.g. text colour or font."
            rows={5}
            value={customIframeCss}
            onChange={(value): void => change({ customIframeCss: value })}
          />
        </Column>
        <Column>
          <h3>Configuration</h3>
          <SelectField
            placeholder="Select type..."
            label="Type"
            options={getTypeOptions(types)}
            value={
              type ? { value: type, label: getType(types, type) } : undefined
            }
            onChange={(option): void => change({ type: option?.value })}
          />
          <SelectField
            placeholder="Select product..."
            label="Product"
            options={enumToOptions(OrganisationFormat)}
            value={
              format
                ? { value: format, label: productMap.get(format) }
                : undefined
            }
            onChange={(option): void => change({ format: option?.value })}
          />
          {isGiftVouchers && (
            <Switch
              label="Has multi guest items"
              flipLabel={true}
              value={hasIndividuals || false}
              switchAction={(value): void => change({ hasIndividuals: value })}
            />
          )}
          <Switch
            label="Has categories"
            flipLabel={true}
            value={categoriesEnabled || false}
            switchAction={(value): void => {
              if (!value) change({ categoryImages: false })
              change({ categoriesEnabled: value })
            }}
          />
          <Switch
            label="Has category images"
            flipLabel={true}
            disabled={!categoriesEnabled}
            value={categoriesEnabled ? categoryImages || false : false}
            switchAction={(value): void => change({ categoryImages: value })}
          />
          {isGiftVoucher && (
            <>
              <h3>Voucher settings</h3>
              <Radio
                label="Voucher size"
                options={enumToRadioOptions(Size)}
                value={size}
                onChange={(value): void => {
                  if (value) change({ size: value })
                }}
              />
              <Switch
                label="Bespoke wallet"
                flipLabel={true}
                value={wallet || false}
                switchAction={(value): void => change({ wallet: value })}
              />
            </>
          )}
        </Column>
      </Main>
    </CardContent>
  )
}
