import {
  alertSuccess,
  AsyncButton,
  Button,
  ButtonStyle,
  Card,
  CardContent,
  CardError,
  CardHeader,
  CardSize,
  Loading,
  onlyChanges,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import Page from '../../components/Page'
import { useOrganisation } from '../../context/OrganisationProvider'
import {
  createUser,
  deleteUser,
  getOrganisations,
  getUser,
  patchUser,
  sendWelcomeEmail,
} from '../../helpers/APIhelper'
import { Path } from '../../types/routes'
import { PartialUser, UserStatus } from '../../types/userTypes'
import UserDetails from './UserDetails'
import UserOrgs from './UserOrgs'
import { getUserErrors, isUserValid } from './userValidation'

const Error = styled.div`
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const EmailMessage = styled.div`
  display: flex;
  align-items: center;
`
export default function User(): ReactElement {
  const { id }: { id: string } = useParams()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState<PartialUser>()
  const [status, setStatus] = useState<UserStatus>()
  const [changes, setChanges] = useState<PartialUser>()
  const history = useHistory()
  const { saveOrganisations } = useOrganisation()

  const errors = getUserErrors({ ...user, ...changes })
  const invalid = !isUserValid({ ...user, ...changes })

  const fetchData = async (): Promise<void> => {
    if (id === 'create') {
      setUser({
        status: UserStatus.Inactive,
      })
    } else {
      const res = await getUser({ id })
      if (res && res.userId) {
        setUser(res)
        setStatus(res.status)
      }
    }
    setLoading(false)
  }
  const fetchOrgs = async (): Promise<void> => {
    // update the org list in case we created a new org
    saveOrganisations(await getOrganisations())
  }

  useEffect(() => {
    fetchOrgs()
    fetchData()
  }, [])

  const saveOrg = async (): Promise<void> => {
    if (user?.userId && changes) {
      const res = await patchUser({
        id: user.userId,
        options: {
          emailAddress: changes.emailAddress || user.emailAddress,
          ...changes,
        },
      })

      if (res) {
        setChanges(undefined)
        setUser(res)
        setStatus(res.status)
        alertSuccess('User saved')
      }
    } else if (user) {
      // Fixes a small error when sending `adminType: null` to the backend
      if (changes?.adminType === null) delete changes?.adminType

      const res = await createUser({
        ...user,
        ...changes,
      })

      if (res) {
        setChanges(undefined)
        setUser(res)
        setStatus(res.status)
        alertSuccess('New user created')
        history.push(`${Path.Users}/${res.userId}`)
      }
    }
  }

  const change = (updates: PartialUser): void => {
    setChanges((p) => {
      if (updates.status) setStatus(updates.status)
      return { ...p, ...updates }
    })
  }

  // if user becomes invalid, set status to `Inactive`. Otherwise, revert to previous status.
  useEffect(() => {
    if ((user || changes) && invalid) {
      // not use `change` so as not to save this status
      setChanges((p) => ({ ...p, status: UserStatus.Inactive }))
    } else if (changes?.status) {
      change({ status })
    }
  }, [invalid])

  const handleDelete = async (): Promise<void> => {
    const res = await deleteUser({ id })
    if (res) {
      alertSuccess('User deleted')
      history.push(Path.Users)
    }
  }

  const handleMail = async (): Promise<void> => {
    const { emailAddress } = user || {}
    if (!emailAddress) return

    const res = await sendWelcomeEmail({ id })
    if (res) {
      alertSuccess('Welcome email sent')
      fetchData()
    }
  }

  const userData = { ...user, ...changes }

  const welcomeEmailString = user?.welcomeEmailSent
    && moment.unix(user.welcomeEmailSent).format('DD MMM')

  const render = !user ? (
    <Error>User {id} not found.</Error>
  ) : (
    <>
      <CardHeader
        title={id === 'create' ? 'Create new user' : `Edit user ${id}`}
      >
        {user.welcomeEmailSent && (
          <EmailMessage>{`Last sent ${welcomeEmailString}`}</EmailMessage>
        )}
        <AsyncButton
          onClick={handleMail}
          showConfirmation={true}
          disabled={invalid || Boolean(onlyChanges(user, changes))}
          buttonStyle={
            user.welcomeEmailSent ? ButtonStyle.Secondary : ButtonStyle.Primary
          }
        >
          {`${user.welcomeEmailSent ? 'Res' : 'S'}end welcome email`}
        </AsyncButton>
        <AsyncButton
          buttonStyle={ButtonStyle.Danger}
          onClick={handleDelete}
          showConfirmation={true}
        >
          Delete
        </AsyncButton>
        <Button
          buttonStyle={ButtonStyle.Secondary}
          onClick={(): void => history.push(Path.Users)}
        >
          Back
        </Button>
        <AsyncButton
          buttonStyle={ButtonStyle.Action}
          disabled={!onlyChanges(user, changes)}
          onClick={saveOrg}
          showConfirmation={invalid}
          confirmationMessage={invalid ? 'There are errors. Save anyway?' : ''}
        >
          Save
        </AsyncButton>
      </CardHeader>
      <CardError>{errors[0]}</CardError>
      <CardContent>
        <UserDetails user={userData} change={change} />
        {user.userId && !userData.adminType && <UserOrgs user={userData} />}
      </CardContent>
    </>
  )

  return (
    <Page>
      <Card cardSize={CardSize.Medium}>
        {loading ? <Loading fullPage={true} /> : render}
      </Card>
    </Page>
  )
}
