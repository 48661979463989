import React, { ReactElement } from 'react'
import { NotificationPosition, Notifications, Role } from '@one-tree/library'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Path } from './types/routes'
import Login from './pages/login/Login'
import Logout from './pages/Logout'
import AuthRoute from './components/routing/AuthRoute'
import Reporting from './pages/reporting/Reporting'
import { AuthProvider } from './context/AuthProvider'
import Sales from './pages/sales/Sales'
import { OrganisationProvider } from './context/OrganisationProvider'
import Organisations from './pages/organisations/Organisations'
import Invoices from './pages/invoices/Invoices'
import Users from './pages/users/Users'
import Dispatch from './pages/dispatch/Dispatch'
import Organisation from './pages/organisation/Organisation'
import Invoice from './pages/invoice/Invoice'
import User from './pages/user/User'
import Home from './pages/Home'
import Masquerade from './pages/Masquerade'
import OrgHosted from './pages/organisation/hosted/OrgHosted'
import Performance from './pages/performance/Performance'

export default function App(): ReactElement {
  return (
    <AuthProvider>
      <OrganisationProvider>
        <Router>
          <Switch>
            <Route path={Path.Login} component={Login} />
            <Route path={Path.Logout} component={Logout} />
            <AuthRoute
              path={`${Path.Masquerade}/:id`}
              component={Masquerade}
              requiredRole={Role.SuperAdmin}
            />
            <AuthRoute
              path={[`${Path.Reporting}/:id`, Path.Reporting]}
              component={Reporting}
              requiredRole={Role.SuperAdmin}
            />
            <AuthRoute
              path={Path.Performance}
              component={Performance}
              requiredRole={Role.SubSuperAdmin}
            />
            <AuthRoute
              path={Path.Sales}
              component={Sales}
              requiredRole={Role.SubSuperAdmin}
            />
            <AuthRoute
              path={[Path.Organisations, Path.OrgSettings, Path.OrgGroups]}
              component={Organisations}
              requiredRole={Role.SubSuperAdmin}
              exact={true}
            />
            <AuthRoute
              path={Path.OrgHosted}
              component={OrgHosted}
              requiredRole={Role.SubSuperAdmin}
            />
            <AuthRoute
              path={`${Path.Organisations}/:id`}
              component={Organisation}
              requiredRole={Role.SubSuperAdmin}
            />
            <AuthRoute
              path={[Path.Invoices, Path.InvoiceSettings]}
              component={Invoices}
              requiredRole={Role.SuperAdmin}
              exact={true}
            />
            <AuthRoute
              path={`${Path.Invoices}/:orgId/:id`}
              component={Invoice}
              requiredRole={Role.SuperAdmin}
            />
            <AuthRoute
              path={`${Path.Users}/:id`}
              component={User}
              requiredRole={Role.SubSuperAdmin}
            />
            <AuthRoute
              path={Path.Users}
              component={Users}
              requiredRole={Role.SubSuperAdmin}
            />
            <AuthRoute
              path={Path.Dispatch}
              component={Dispatch}
              requiredRole={Role.Dispatch}
            />
            <Route path="/" component={Home} />
          </Switch>
        </Router>
        <Notifications
          notificationPosition={NotificationPosition.BottomRight}
        />
      </OrganisationProvider>
    </AuthProvider>
  )
}
