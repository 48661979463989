import {
  AsyncButton,
  Button,
  ButtonStyle,
  Card,
  CardContent,
  CardHeader,
  CardSize,
  ISelectOption,
  Loading,
  SelectField,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Page from '../../components/Page'
import {
  getInvoice,
  getOrgAccessToken,
  patchInvoice,
} from '../../helpers/APIhelper'
import { IInvoice, InvoiceStatus } from '../../types/adminTypes'
import { Path } from '../../types/routes'
import { invoiceStatusMap } from '../invoices/InvoiceHelper'
import InvoiceLayout from './InvoiceLayout'

const Error = styled.div`
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export default function Invoice(): ReactElement {
  const { orgId, id }: { orgId: string; id: string } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [invoice, setInvoice] = useState<IInvoice>()
  const [status, setStatus] = useState<ISelectOption<InvoiceStatus>>()

  const statusOptions = Array.from(invoiceStatusMap.entries())
    .filter(([value]) => value !== InvoiceStatus.PaidGoCardless)
    .filter(([value]) => value !== status?.value)
    .map(([value, label]) => ({
      value,
      label,
    }))

  const fetchData = async (): Promise<void> => {
    const res = await getInvoice({ orgId, id })
    if (res) {
      setInvoice(res)
      setStatus({
        value: res.status,
        label: invoiceStatusMap.get(res.status),
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const hasChanges = invoice?.status !== status?.value

  const save = async (): Promise<void> => {
    if (invoice && status) {
      if (await patchInvoice({ orgId, id, status: status.value })) fetchData()
    }
  }

  const downloadPdf = async (): Promise<void> => {
    const pdfToken = await getOrgAccessToken({ orgId })
    if (pdfToken && invoice) {
      // setTimeout is needed for safari and firefox to not block the new tab
      // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari/70463940#70463940
      setTimeout(() => {
        window.open(`${invoice.pdfUrl}?token=${pdfToken}`, '_blank')
      })
    }
  }

  const render = !invoice ? (
    <Error>Invoice {id} not found.</Error>
  ) : (
    <>
      <CardHeader title={`Invoice ${id}`}>
        <AsyncButton onClick={downloadPdf}>Download PDF</AsyncButton>
        <Button
          buttonStyle={ButtonStyle.Secondary}
          onClick={(): void => history.push(Path.Invoices)}
        >
          Back
        </Button>
        <AsyncButton
          buttonStyle={ButtonStyle.Action}
          disabled={!hasChanges}
          onClick={save}
        >
          Save
        </AsyncButton>
      </CardHeader>
      <CardContent>
        <SelectField
          label="Status"
          options={statusOptions}
          value={status}
          onChange={(option): void => {
            if (option) setStatus(option)
          }}
          style={{ width: '100px' }}
        />
        <InvoiceLayout invoice={invoice} />
      </CardContent>
    </>
  )

  return (
    <Page warnBeforeExit={hasChanges}>
      <Card cardSize={CardSize.Medium}>
        {loading ? <Loading fullPage={true} /> : render}
      </Card>
    </Page>
  )
}
