import axios from 'axios'
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Role, User } from '@one-tree/library'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import { getRefreshToken } from '../helpers/APIhelper'
import { IDecodedToken, ITokenPair } from '../types/APItypes'

interface IState {
  user: User | null
  saveAccessToken: (tokenPair: ITokenPair) => Role | false
  clearAuth: () => void
}

let refreshTimer: ReturnType<typeof setTimeout> | null = null

const AuthContext = createContext<IState | undefined>(undefined)

function AuthProvider({ children }: { children: ReactNode }): ReactElement {
  const [token, setToken] = useState<string | null>(
    sessionStorage.getItem('token') || null,
  )
  const [user, setUser] = useState<User | null>(
    JSON.parse(sessionStorage.getItem('user') || 'null'),
  )
  const [refreshToken, setRefreshToken] = useState<string | null>(null)

  // Save the token and role in session storage
  const saveAccessToken = (tokenPair: ITokenPair): Role | false => {
    const { token: newToken, refreshToken: newRefreshToken } = tokenPair

    sessionStorage.removeItem('token')
    sessionStorage.setItem('token', newToken)

    setToken(newToken)
    if (newRefreshToken) setRefreshToken(newRefreshToken)

    const userToken: IDecodedToken = jwt_decode(newToken)
    if (userToken) {
      const { email, role } = userToken
      setUser({ email, role })
      sessionStorage.setItem('user', JSON.stringify(userToken))
      return role
    }
    return false
  }

  // Remove the token and clear sessionStorage
  const clearAuth = (): void => {
    sessionStorage.clear()
    localStorage.clear()

    setToken(null)
    setRefreshToken(null)

    // Remove the token from Axios
    delete axios.defaults.headers.common.Authorization
    setUser(null)
  }

  const onTimerExpiry = async (): Promise<void> => {
    if (refreshToken) {
      const response = await getRefreshToken({ refreshToken })
      if (response) {
        saveAccessToken(response)
      }
    }
  }

  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  useEffect(() => {
    if (token && refreshToken) {
      const decodedToken: IDecodedToken = jwt_decode(token)

      // The token expiry should always be in the future
      if (decodedToken.exp > moment().unix()) {
        // Clear old timer first so we don't have multiple running
        if (refreshTimer) {
          clearTimeout(refreshTimer)
        }

        // Set a timeout for 30 seconds before the token expires to refresh it with a new one
        const refreshSeconds = decodedToken.exp - moment().unix() - 30
        refreshTimer = setTimeout(onTimerExpiry, refreshSeconds * 1000)
      } else {
        // Remove the token if it has expired, this shouldn't happen
        clearAuth()
      }
    }
  }, [token, refreshToken])

  const value = useMemo(
    () => ({
      user,
      saveAccessToken,
      clearAuth,
    }),
    [user],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

function useAuth(): IState {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth }
