import {
  CurrencyCode,
  formatCurrency,
  IColumn,
  IFilters,
  RequestWithFilters,
  transformDate,
  TransformDateFormat,
} from '@one-tree/library'
import React, { ReactNode } from 'react'
import DisplayOrganisation from '../../components/DisplayOrganisation'
import { ClickOrg } from '../../helpers/TableHelper'
import { IPerformance } from '../../types/orgTypes'
import { Path } from '../../types/routes'

export const getPerformanceColumns = (
  navigate: (path: Path, id?: number, usersFilterOrg?: number) => void,
): IColumn<IPerformance>[] => {
  const cur = (value?: number, currencyCode?: CurrencyCode): string => {
    if (value || value === 0) {
      return formatCurrency(value, currencyCode)
    }
    return '-'
  }

  const columns: IColumn<IPerformance>[] = [
    {
      accessor: 'id',
      header: 'Organisation',
      display: ({ id, name, format }) => (
        <ClickOrg onClick={(): void => navigate(Path.Organisations, id)}>
          <DisplayOrganisation name={name} format={format} />
        </ClickOrg>
      ),
      clickable: false,
    },
    {
      accessor: 'activationDate',
      header: 'Activation date',
      display: ({ activationDate }) => (activationDate
        ? transformDate(activationDate, TransformDateFormat.NumbersAndSlashes)
        : '-'),
    },
    {
      accessor: 'serviceFee',
      header: 'Fee',
      parent: 'Service',
      display: ({ serviceFee, currencyCode }) => cur(serviceFee, currencyCode),
    },
    {
      accessor: 'serviceIncome',
      header: 'Income',
      parent: 'Service',
      display: ({ serviceIncome, currencyCode }) => cur(serviceIncome, currencyCode),
    },
    {
      accessor: 'commissionFee',
      header: 'Fee',
      parent: 'Commission',
      display: ({ commissionFee }) => `${commissionFee}%`,
    },
    {
      accessor: 'commissionIncome',
      header: 'Income',
      parent: 'Commission',
      display: ({ commissionIncome, currencyCode }) => cur(commissionIncome, currencyCode),
    },
    {
      accessor: 'incomeTotal',
      header: 'Total income',
      display: ({ incomeTotal, currencyCode }) => cur(incomeTotal, currencyCode),
    },
    {
      accessor: 'outstanding',
      header: 'Outstanding',
      display: ({ outstanding, currencyCode }) => cur(outstanding, currencyCode),
    },
    {
      accessor: 'totalQuantity',
      header: 'Qty',
      parent: 'Total sold',
      display: ({ totalQuantity }): ReactNode => totalQuantity || '-',
    },
    {
      accessor: 'totalValue',
      header: 'Val',
      parent: 'Total sold',
      display: ({ totalValue, currencyCode }) => cur(totalValue, currencyCode),
    },
  ]
  return columns
}

export type PerformanceTableState = RequestWithFilters<
IPerformance,
  IFilters<IPerformance>[]
>
