import { CardTab, CardTabs } from '@one-tree/library'
import React, { ReactElement } from 'react'
import { Path } from '../../types/routes'

interface IOrgTabsProps {
  pathId: string
  setChangeTab?: (path: string) => void
  disabled?: boolean
}
export default function OrgTabs(props: IOrgTabsProps): ReactElement {
  // TODO
  // eslint-disable-next-line
  const { pathId, disabled, setChangeTab } = props
  const orgPath = `${Path.Organisations}/${pathId}`

  return (
    <CardTabs>
      <CardTab title="Details" to={orgPath} onClick={setChangeTab} />
      <CardTab
        title="Finance"
        to={`${orgPath}/finance`}
        onClick={setChangeTab}
      />
      <CardTab
        title="Hosted page"
        to={`${orgPath}/hosted`}
        onClick={setChangeTab}
        disabled={disabled}
      />
    </CardTabs>
  )
}
