import {
  Card, CardSize, CardTab, CardTabs,
} from '@one-tree/library'
import React, { ReactElement, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import Page from '../../components/Page'
import { Path } from '../../types/routes'
import OrgGroups from './OrgGroups'
import OrgSettings from './OrgSettings'
import OrgTable from './OrgTable'

export default function Organisations(): ReactElement {
  const [hasChanges, setHasChanges] = useState(false)

  return (
    <Page warnBeforeExit={hasChanges}>
      <Card cardSize={CardSize.Full}>
        <CardTabs>
          <CardTab title="Organisations" to={Path.Organisations} />
          <CardTab title="Settings" to={Path.OrgSettings} />
          <CardTab title="Groups" to={Path.OrgGroups} />
        </CardTabs>

        <Switch>
          <Route
            exact={true}
            path={Path.Organisations}
            render={(): ReactElement => {
              if (hasChanges) setHasChanges(false)
              return <OrgTable />
            }}
          />
          <Route exact={true} path={Path.OrgSettings}>
            <OrgSettings
              hasChanges={hasChanges}
              setHasChanges={setHasChanges}
            />
          </Route>
          <Route exact={true} path={Path.OrgGroups}>
            <OrgGroups />
          </Route>
        </Switch>
      </Card>
    </Page>
  )
}
