import { trueOr0 } from '@one-tree/library'
import {
  OrgStatus,
  PartialAdminOrg,
  PaymentMethod,
  PaymentProvider,
} from '../../types/orgTypes'

export const orgNameTest = (organisation?: PartialAdminOrg): string | false => {
  if (!organisation?.name) return 'Name required'
  return false
}

export const orgDisplayNameTest = (
  organisation?: PartialAdminOrg,
): string | false => {
  if (!organisation?.displayName) return 'Display name required'
  return false
}

export const orgTypeTest = (organisation?: PartialAdminOrg): string | false => {
  if (!organisation?.type) return 'Type required'
  return false
}

export const orgProductTest = (
  organisation?: PartialAdminOrg,
): string | false => {
  if (!organisation?.format) return 'Product required'
  return false
}

export const orgSizeTest = (organisation?: PartialAdminOrg): string | false => {
  if (!organisation?.size) return 'Voucher size required'
  return false
}

export const orgProviderTest = (
  organisation?: PartialAdminOrg,
): string | false => {
  if (!organisation?.paymentProvider) return 'Payment provider required'
  const { paymentProvider } = organisation
  if (paymentProvider === PaymentProvider.Stripe) {
    if (!organisation.stripePublicKey) return 'Stripe public key required'
    if (!organisation.stripeSecretKey) return 'Stripe secret key required'
  }
  if (paymentProvider === PaymentProvider.PayPal) {
    if (!organisation.paypalSecret) return 'PayPal secret required'
    if (!organisation.paypalWebhookId) return 'PayPal webhook ID required'
  }
  if (paymentProvider === PaymentProvider.Trust) {
    if (!organisation.trustId) return 'Trust reference required'
    if (!organisation.trustPassword) return 'Trust password required'
  }
  return false
}

export const orgCurrencyTest = (
  organisation?: PartialAdminOrg,
): string | false => {
  if (!organisation?.currencyCode) return 'Currency code required'
  return false
}

export const orgMethodTest = (
  organisation?: PartialAdminOrg,
): string | false => {
  if (!organisation?.paymentMethod) return 'Payment method required'
  return false
}

export const orgMandateTest = (
  organisation?: PartialAdminOrg,
): string | false => {
  if (
    organisation
    && organisation.paymentMethod === PaymentMethod.GoCardless
    && !organisation.gcMandate
  ) {
    return 'GoCardless mandate required'
  }
  return false
}

export const orgCommissionTest = (
  organisation?: PartialAdminOrg,
): string | false => {
  if (!trueOr0(organisation?.commissionFee)) return 'Commission required'
  return false
}

export const getOrgErrors = (organisation?: PartialAdminOrg): string[] => {
  const errorArray: string[] = []

  const add = (
    errorTest: (organisation?: PartialAdminOrg) => string | false,
  ): void => {
    if (!organisation) errorArray.push('Can\'t find organisation')

    const error = errorTest(organisation)
    if (error) errorArray.push(error)
  }

  add(orgNameTest)
  add(orgDisplayNameTest)
  add(orgTypeTest)
  add(orgProductTest)
  add(orgSizeTest)
  add(orgProviderTest)
  add(orgCurrencyTest)
  add(orgMethodTest)
  add(orgMandateTest)
  add(orgCommissionTest)

  return errorArray
}

export const isOrgValid = (organisation?: PartialAdminOrg): boolean => {
  if (getOrgErrors(organisation).length) return false
  return true
}

export const activateError = (organisation?: PartialAdminOrg): boolean => {
  const { outstandingFields, status } = organisation || {}

  const cannotActivate = outstandingFields
    && outstandingFields.length > 0
    && status === OrgStatus.Active

  return cannotActivate || false
}

export const getActivationError = (
  organisation?: PartialAdminOrg,
): string | false => {
  const { outstandingFields, status } = organisation || {}

  const cannotActivateReason = outstandingFields
    && outstandingFields.length > 0
    && status === OrgStatus.Active
    && outstandingFields[0]

  return cannotActivateReason || false
}
