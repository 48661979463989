import {
  CurrencyCode,
  getOverviewColor,
  IGraphData,
  IHistoricData,
  ISelectOption,
  ISeries,
  Loading,
  OrganisationFormat,
  renderTotal,
  ReportDateRange,
  ReportingWidget,
  ReportMetric,
  ReportMetricType,
  Switch,
} from '@one-tree/library'
import { lighten } from 'polished'
import React, { ReactElement, useMemo } from 'react'
import { AxisOptions, Chart as ReactGraph } from 'react-charts'
import styled from 'styled-components'
import logo from '../../assets/watermark.png'
import { metricExists } from '../../helpers/reportingHelper'

const GraphStyles = styled.div`
  min-height: 300px;
  margin: 20px;
  .tick {
    line {
      display: none;
    }
    text {
      /* needed to override react-charts inline styles  */
      font-size: 1rem !important;
    }
  }
  .tickLabel {
    white-space: pre;
  }
`
interface IGraphProps {
  currency?: CurrencyCode
  loading: boolean
  setLoading: (value: boolean) => void
  data: IHistoricData[] | undefined
  dateRange: ReportDateRange
  metric: ReportMetric
  metricType: ReportMetricType
  product?: ISelectOption<OrganisationFormat>
  compare: boolean
  setCompare: (value: boolean) => void
  prevData?: IHistoricData[]
}
export default function Graph(props: IGraphProps): ReactElement {
  const {
    currency = CurrencyCode.GBP,
    loading,
    setLoading,
    data,
    metric,
    metricType,
    dateRange,
    product,
    compare,
    setCompare,
    prevData,
  } = props

  const handleCompare = (): void => {
    setLoading(true)
    setCompare(!compare)
  }

  const dataHasMetric = metricExists(data, metric)

  const xAxis = useMemo(
    (): AxisOptions<IGraphData> => ({
      getValue: (datum: IGraphData): string => datum.label,
    }),
    [],
  )

  const scaleFormatter = (value: number | null): string => {
    const result = value || value === 0 ? renderTotal(value, metricType, currency) : ''
    return result
  }
  const yAxis = useMemo(
    (): AxisOptions<IGraphData> => ({
      min: 0,
      elementType: 'bar',
      getValue: (datum: IGraphData): number => datum.total || 0,
      formatters: { scale: scaleFormatter },
    }),
    [metricType],
  )

  const seriesData = {
    label: '',
    data:
      !data || !data.length || !dataHasMetric
        ? [{ label: 'loading', total: 0 }]
        : data.map((bin) => ({
          label: bin.label,
          total: bin[metric][metricType],
        })),
  }
  const seriesPrevData = prevData
    && prevData.length
    && dataHasMetric && {
    label: '',
    data: prevData.map((bin) => ({
      label: bin.label,
      total: bin[metric][metricType],
    })),
  }

  const showPrevData = compare && !loading

  const series: ISeries[] = useMemo(
    () => (seriesPrevData && showPrevData
      ? [seriesPrevData, seriesData]
      : [seriesData]),
    [data, prevData, compare, loading, metric, metricType],
  )

  const selectedColor = getOverviewColor(metric)
  const previousColor = lighten(0.25, selectedColor)

  const showPrevious = (
    <>
      , <span style={{ color: previousColor }}>{dateRange.previous}</span>
    </>
  )

  const renderTitle = (
    <div>
      Graph of {product?.label} {metric} by {metricType}:{' '}
      {<span style={{ color: selectedColor }}>{dateRange.selected}</span>}{' '}
      {compare && showPrevious}
    </div>
  )

  const defaultColors = seriesPrevData && showPrevData
    ? [previousColor, selectedColor]
    : [selectedColor]

  const controls = loading ? (
    <Loading />
  ) : (
    <Switch
      label="Compare with previous"
      switchAction={handleCompare}
      value={compare}
    />
  )

  return (
    <ReportingWidget
      title={renderTitle}
      controls={controls}
      downloadPng="overview-chart"
      logo={logo}
    >
      <GraphStyles>
        {!data || !data.length || loading ? (
          <Loading fullPage={true} />
        ) : (
          <ReactGraph
            options={{
              data: series,
              primaryAxis: xAxis,
              secondaryAxes: [yAxis],
              defaultColors,
              tooltip: false,
            }}
          />
        )}
      </GraphStyles>
    </ReportingWidget>
  )
}
