import React, { ReactElement } from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth } from '../context/AuthProvider'
import { Path } from '../types/routes'

export default function Logout(): ReactElement {
  useAuth().clearAuth()

  return <Redirect to={Path.Login} />
}
