import { CurrencyCode, FontSize, OrganisationFormat } from '@one-tree/library'

export enum OrgStatus {
  Active = 'active',
  Suspended = 'suspended',
  Cancelled = 'cancelled',
  Draft = 'draft',
}

export interface IFee {
  percent?: number
  fixed?: number
}
export enum PaymentMethod {
  Manual = 'manual',
  GoCardless = 'gocardless',
}
export enum Size {
  A4 = 'A4',
  A5 = 'A5',
}
export enum PaymentProvider {
  Stripe = 'stripe',
  PayPal = 'paypal',
  Trust = 'securetrading',
}
export interface IAdminOrg {
  id: number
  status: OrgStatus
  code: string
  name: string
  format: OrganisationFormat
  displayName: string
  type: string
  currencyCode: CurrencyCode
  createdOn: number
  lastActivated?: number
  categoryImages: boolean
  setupFee?: string
  size: Size
  vat: string
  stripePublicKey?: string
  stripeSecretKey?: string
  stripeWebhookSecret?: string
  paymentMethod: PaymentMethod
  notes: string
  fbPixelId: string
  gaTrackingId: string
  hasIndividuals: boolean
  customIframeCss: string
  salesCommission?: number
  minimumCommission: string
  wallet: boolean
  paymentProvider: PaymentProvider
  international: boolean
  categoriesEnabled: boolean
  commissionFee: number
  licenceFee: number
  group: string
  groupId: number | null
  outstanding: number
  registration: string
  gcMandate?: string
  salesCommissionUntil?: number
  paypalClientId?: string
  paypalSecret?: string
  paypalWebhookId?: string
  trustId?: string
  trustPassword?: string
  invoiceName?: string
  outstandingFields?: string[]
}
export type PartialAdminOrg = Partial<IAdminOrg>

export enum Alignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export interface IHostedPage {
  hasHostedPage: boolean
  bespoke: boolean
  url: string
  favicon: string | null
  faviconFile: string | null
  topBarFontFamily: string
  topBarFontSize: FontSize
  topBarFontColour: string
  hero: string | null
  heroFile: string | null
  heroText: string
  heroColour: string
  heroFontFamily: string
  heroFontSize: FontSize
  heroFontColour: string
  mainBackgroundColour: string
  mainHeaderText: string
  mainHeaderFontFamily: string
  mainHeaderFontColour: string
  mainHeaderWidth: number
  mainHeaderAlignment: Alignment
  mainHeaderBold: boolean
  mainHeaderItalic: boolean
  mainParagraphText: string
  mainParagraphFontFamily: string
  mainParagraphFontColour: string
  mainParagraphWidth: number
  mainParagraphAlignment: Alignment
  mainParagraphBold: boolean
  mainParagraphItalic: boolean
  footerFontColour: string
  footerFontFamily: string
  footerFontSize: FontSize
  footerBackgroundColour: string
  privacyLink: string
  css: string
  termsAndConditions: string
}
export type PartialHosted = Partial<IHostedPage>

export type PaymentModalData =
  | { id: number; organisationId: number }
  | undefined

export interface IPerformance {
  id: number
  status: OrgStatus
  group: string
  type: string
  name: string
  format: OrganisationFormat
  currencyCode: CurrencyCode
  activationDate?: number
  serviceFee?: number
  serviceIncome?: number
  commissionFee?: number
  commissionIncome?: number
  incomeTotal?: number
  outstanding?: number
  postPurchaserQuantity?: number
  postPurchaserValue?: number
  emailPurchaserQuantity?: number
  emailPurchaserValue?: number
  postRecipientQuantity?: number
  postRecipientValue?: number
  emailRecipientQuantity?: number
  emailRecipientValue?: number
  printAtDeskQuantity?: number
  printAtDeskValue?: number
  totalQuantity?: number
  totalValue?: number
}

export interface IGroup {
  id: number
  name: string
  organisations: { id: number; name?: string }[]
}

export interface ITypes {
  id: number
  name: string
}
