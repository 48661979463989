import { Role } from '@one-tree/library'
import React, { ReactElement } from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth } from '../context/AuthProvider'
import { Path } from '../types/routes'

export default function Home(): ReactElement {
  const { user } = useAuth()
  const { role } = user || {}

  switch (role) {
  case Role.SuperAdmin:
    return <Redirect to={Path.Reporting} />
  case Role.SubSuperAdmin:
    return <Redirect to={Path.Sales} />
  case Role.Dispatch:
    return <Redirect to={Path.Dispatch} />
  default:
    return <Redirect to={Path.Login} />
  }
}
