import { Loading } from '@one-tree/library'
import React, { CSSProperties, ReactElement, ReactNode } from 'react'
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Styles = styled.div`
  animation: ${fadeIn} 0.4s ease-in-out;
`

interface IFadeInProps {
  ready?: boolean
  children: ReactNode
  style?: CSSProperties
}
export default function FadeIn(props: IFadeInProps): ReactElement {
  const { ready, children, style } = props

  return ready ? (
    <Styles>{children}</Styles>
  ) : (
    <Loading fullPage={true} style={style} />
  )
}
