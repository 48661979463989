import {
  alertSuccess,
  AsyncButton,
  Button,
  ButtonStyle,
  capitalize,
  CardContent,
  CardHeader,
  colors,
  formatCurrency,
  getCSVExportTitle,
  IFilters,
  Loading,
  Modal,
  NumberField,
  Order,
  SelectField,
  Table,
  TextArea,
  transformDate,
} from '@one-tree/library'
import download from 'downloadjs'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import DisplayOrganisation from '../../components/DisplayOrganisation'
import { useOrganisation } from '../../context/OrganisationProvider'
import {
  addToInvoice,
  exportInvoices,
  getInvoiceManual,
  getInvoices,
} from '../../helpers/APIhelper'
import {
  IInvoice,
  IInvoiceAddition,
  InvoiceStatus,
} from '../../types/adminTypes'
import { Path } from '../../types/routes'
import { getOrgOptions } from '../organisations/OrgHelper'
import { getInvoiceColumns, InvoiceTableState } from './InvoiceHelper'

const ModalContents = styled.div`
  h2,
  h3 {
    margin: 0;
  }
  min-width: 300px;
  display: grid;
  grid-row-gap: 10px;
`
const Previous = styled.div`
  h3 {
    margin: 0 0 5px 0;
    font-size: 1.1em;
  }
  border: 1px solid ${colors.lightGray};
  border-radius: 4px;
  background-color: ${colors.lightestGray};
  padding: 10px;
`
const PreviousTable = styled.table`
  width: 100%;
  td {
    max-width: 300px;
    vertical-align: top;
    padding: 5px 8px 5px 0;
  }
  td:last-child {
    float: right;
  }
  border-collapse: collapse;
`
const ModalButtons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`
export default function InvoiceTable(): ReactElement {
  const { organisations } = useOrganisation()
  const orgOptions = getOrgOptions(organisations)
  const history = useHistory()
  const navigateToOrg = (path: Path, id: number | string): void => {
    history.push(`${path}/${id.toString()}`, { backLink: Path.Invoices })
  }
  const invoiceColumns = getInvoiceColumns(organisations, navigateToOrg)

  const navigateToInvoice = (orgId: number, id: number): void => {
    history.push(`${Path.Invoices}/${orgId}/${id}`, { backLink: Path.Invoices })
  }

  const statusOptions = [
    { value: InvoiceStatus.Open, label: 'Open' },
    { value: InvoiceStatus.Cancelled, label: 'Cancelled' },
    { value: InvoiceStatus.Paid, label: 'Paid' },
    { value: InvoiceStatus.PaidGoCardless, label: 'Paid GoCardless' },
  ]

  const filters: IFilters<IInvoice>[] = [
    {
      accessor: 'organisationId',
      options: organisations.map(({ name, id }) => ({
        value: id,
        label: name,
      })),
      placeholder: 'Select organisation...',
      slug: true,
    },
    {
      accessor: 'organisationId',
      options: [],
      placeholder: 'Filter by group...',
    },
    {
      accessor: 'status',
      options: statusOptions,
      placeholder: 'Filter by status...',
    },
  ]

  const [addToInvoiceOrg, setAddToInvoiceOrg] = useState<number>()
  const thisOrg = organisations.find(({ id }) => id === addToInvoiceOrg)
  const [description, setDescription] = useState<string>()
  const [amount, setAmount] = useState<number | null>(null)
  const clearAddToInvoice = (): void => {
    setAddToInvoiceOrg(undefined)
    setDescription('')
    setAmount(null)
  }
  const saveToInvoice = async (): Promise<void> => {
    if (addToInvoiceOrg && description && amount) {
      const res = await addToInvoice({
        id: addToInvoiceOrg,
        addition: { description, amount },
      })
      if (res) {
        alertSuccess(
          `"${res.description}: £${res.amount}" added to next ${thisOrg?.name} invoice`,
        )
      }
      clearAddToInvoice()
    }
  }

  const [tableState, setTableState] = useState<InvoiceTableState>()
  const exportCsv = async (): Promise<void> => {
    const res = await exportInvoices({ ...tableState })
    const { fromDate, toDate, status } = tableState || {}
    if (res) {
      download(
        res,
        `Invoices - ${getCSVExportTitle(fromDate, toDate, capitalize(status))}`,
      )
    }
  }

  // TODO put in own component
  const [loading, setLoading] = useState(false)
  const [additions, setAdditions] = useState<IInvoiceAddition[]>([])
  const getManual = async (orgId: number): Promise<void> => {
    setLoading(true)
    const res = await getInvoiceManual({ orgId })
    if (res) setAdditions(res)
    setLoading(false)
  }
  const renderAdditions = additions && additions.length ? (
    <Previous>
      <h3>Previously added:</h3>
      <PreviousTable>
        {additions.map((addition, index) => {
          const currency = organisations.find(
            ({ id }) => id === addToInvoiceOrg,
          )?.currencyCode
          const additionAmount = formatCurrency(addition.amount, currency)
          const key = `${index}`

          const { timestamp } = addition
          const date = timestamp ? transformDate(timestamp) : '-'

          return (
            <tr key={key}>
              <td>{date}</td>
              <td style={{ fontStyle: 'italic' }}>{addition.description}</td>
              <td style={{ fontWeight: 'bold' }}>{additionAmount}</td>
            </tr>
          )
        })}
      </PreviousTable>
    </Previous>
  ) : null

  return (
    <>
      <CardHeader title="All invoices">
        <SelectField
          placeholder="Add to invoice..."
          options={orgOptions}
          value={
            addToInvoiceOrg
              ? {
                value: addToInvoiceOrg,
                label: thisOrg?.name,
              }
              : undefined
          }
          onChange={(option): void => {
            if (option) {
              getManual(option.value)
              setAddToInvoiceOrg(option.value)
            }
          }}
        />
        <AsyncButton onClick={exportCsv}>Export CSV</AsyncButton>
      </CardHeader>
      <CardContent>
        <Table
          fetch={getInvoices}
          columns={invoiceColumns}
          options={{
            orderBy: 'date',
            orderDirection: Order.Desc,
          }}
          search={true}
          filters={filters}
          dates={{
            startDate: moment().subtract(1, 'year').add(1, 'day').unix(),
            endDate: moment().unix(),
          }}
          rowLink={{
            onClick: ({ organisationId, id }): void => navigateToInvoice(organisationId, id),
          }}
          getState={setTableState}
        />
      </CardContent>
      <Modal
        onModalClose={(): void => {
          setAddToInvoiceOrg(undefined)
        }}
        modalOpen={Boolean(addToInvoiceOrg)}
      >
        {loading ? (
          <Loading />
        ) : (
          <ModalContents>
            <h2>
              Add to{' '}
              <DisplayOrganisation
                format={thisOrg?.format}
                name={thisOrg?.name}
              />
            </h2>
            {renderAdditions}
            <TextArea
              placeholder="Add item description..."
              value={description}
              onChange={setDescription}
              rows={3}
            />
            <NumberField
              placeholder="Add item amount..."
              onChange={setAmount}
              value={amount}
              subtitle="Exclude VAT. Add a credit note by adding a negative invoice item."
              minimumValue={-10000}
            />
            <ModalButtons>
              <Button
                buttonStyle={ButtonStyle.Secondary}
                onClick={(): void => {
                  clearAddToInvoice()
                }}
              >
                Cancel
              </Button>
              <AsyncButton
                buttonStyle={ButtonStyle.Action}
                onClick={saveToInvoice}
                disabled={!description || !amount}
              >
                Save
              </AsyncButton>
            </ModalButtons>
          </ModalContents>
        )}
      </Modal>
    </>
  )
}
