import {
  AdminRole,
  colors,
  enumToOptions,
  InputField,
  InputGroup,
  SelectField,
  transformDate,
} from '@one-tree/library'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { PartialUser, UserStatus } from '../../types/userTypes'
import { adminRoleMap, userStatusMap } from '../users/UsersHelper'
import { isUserValid } from './userValidation'

const Internal = styled.div`
  border: 1px solid ${colors.lightGray};
  border-radius: 4px;
  background-color: ${colors.lightestGray};
  padding: 20px;

  display: flex;
  align-items: center;
  strong {
    margin: 17px 0 0 20px;
  }
`
const Styles = styled.div`
  display: grid;
  grid-row-gap: 20px;
`
interface IProps {
  user: PartialUser
  change: (updates: PartialUser) => void
}
export default function UserDetails(props: IProps): ReactElement {
  const {
    user,
    user: {
      title,
      emailAddress,
      firstName,
      lastName,
      jobTitle,
      status,
      lastLogin,
      adminType,
    },
    change,
  } = props
  const invalid = !isUserValid(user)

  return (
    <Styles>
      <Internal>
        <SelectField
          placeholder="Select role..."
          label="One Tree admin role"
          options={[
            { value: null, label: 'No admin role' },
            ...enumToOptions(AdminRole),
          ]}
          onChange={(option): void => change({ adminType: option?.value })}
          value={
            adminType
              ? { value: adminType, label: adminRoleMap.get(adminType) }
              : { value: null, label: 'No admin role' }
          }
        />
        {lastLogin && (
          <strong>{`Last login: ${transformDate(lastLogin)}`}</strong>
        )}
      </Internal>
      <SelectField
        style={{ width: '200px' }}
        placeholder="Select status..."
        label="Status"
        options={[
          { value: UserStatus.Active, label: 'Active' },
          { value: UserStatus.Inactive, label: 'Inactive' },
        ]}
        value={{
          value: status,
          label: status && userStatusMap.get(status),
        }}
        onChange={(option): void => change({ status: option?.value })}
        disabled={invalid}
        isError={invalid}
      />
      <InputGroup>
        <InputField
          placeholder="Add an email address..."
          label="Email"
          value={emailAddress}
          onChange={(value): void => change({ emailAddress: value })}
          isLong={true}
        />
        <InputField
          placeholder="Add a job title..."
          label="Job title"
          value={jobTitle}
          onChange={(value): void => change({ jobTitle: value })}
          isLong={true}
        />
      </InputGroup>
      <InputGroup>
        <InputField
          style={{ width: '100px' }}
          placeholder="Add a title..."
          label="Title"
          value={title}
          onChange={(value): void => change({ title: value })}
        />
        <InputField
          placeholder="Add a first name..."
          label="First name"
          value={firstName}
          onChange={(value): void => change({ firstName: value })}
          isLong={true}
        />
        <InputField
          placeholder="Add a last name..."
          label="Last name"
          value={lastName}
          onChange={(value): void => change({ lastName: value })}
          isLong={true}
        />
      </InputGroup>
    </Styles>
  )
}
