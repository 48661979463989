import {
  AsyncButton,
  Button,
  ButtonStyle,
  CardContent,
  CardHeader,
  CurrencyCode,
  CurrencyField,
  Loading,
  NumberField,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { getGlobalSettings, patchGlobalSettings } from '../../helpers/APIhelper'
import { IGlobalSettings } from '../../types/APItypes'
import { Path } from '../../types/routes'

const Styles = styled.div`
  display: inline-grid;
  grid-row-gap: 20px;
`
interface IInvoiceSettingsProps {
  changes: boolean
  setChanges: (value: true) => void
}
export default function InvoiceSettings(
  props: IInvoiceSettingsProps,
): ReactElement {
  const { changes, setChanges } = props
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<IGlobalSettings>()

  const fetchData = async (): Promise<void> => {
    const res = await getGlobalSettings()
    if (res) setData(res)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const update = <T extends unknown>(
    value: T,
    key: keyof IGlobalSettings,
  ): void => {
    setData((prevState) => {
      if (!prevState) return prevState

      return {
        ...prevState,
        [key]: value,
      }
    })
    setChanges(true)
  }

  const handleSave = async (): Promise<void> => {
    if (!data) return

    const res = await patchGlobalSettings(data)
    if (res) setData(res)
  }

  const render = !data ? (
    'No data'
  ) : (
    <Styles>
      <NumberField
        label="VAT % rate"
        value={data.vat}
        onChange={(value: number | null): void => update(value, 'vat')}
      />
      <CurrencyField
        label="Invoice when outstanding is at least"
        currency={CurrencyCode.GBP}
        onChange={(value: string): void => update(value, 'invoiceMinimum')}
        initValue={data.invoiceMinimum.toString()}
      />
    </Styles>
  )

  const history = useHistory()

  return (
    <>
      <CardHeader title="Global invoice settings">
        <Button
          buttonStyle={ButtonStyle.Secondary}
          onClick={(): void => history.push(Path.Invoices)}
        >
          Cancel
        </Button>
        <AsyncButton
          buttonStyle={ButtonStyle.Action}
          onClick={handleSave}
          disabled={!changes}
        >
          Save
        </AsyncButton>
      </CardHeader>
      <CardContent>
        {loading ? <Loading fullPage={true} /> : render}
      </CardContent>
    </>
  )
}
