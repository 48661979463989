import {
  AsyncButton,
  Button,
  ButtonStyle,
  Card,
  CardContent,
  CardHeader,
  CardSize,
  IFilters,
  ISelectOption,
  Order,
  Table,
} from '@one-tree/library'
import download from 'downloadjs'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Page from '../../components/Page'
import { useOrganisation } from '../../context/OrganisationProvider'
import { exportUsers, getUsers } from '../../helpers/APIhelper'
import { fetchGroups } from '../../helpers/groupHelper'
import { Path } from '../../types/routes'
import { IUser } from '../../types/userTypes'
import { getOrgOptions } from '../organisations/OrgHelper'
import { getUserColumns, UserTableState } from './UsersHelper'

export default function Users(): ReactElement {
  const userColumns = getUserColumns()
  const { organisations } = useOrganisation()
  const history = useHistory()

  const { state }: { state: { orgId?: number } } = useLocation()
  const { orgId } = state || {}
  const orgOptions = getOrgOptions(organisations)
  const defaultOrg = orgOptions.find(({ value }) => value === orgId)

  const [groupOptions, setGroupOptions] = useState<ISelectOption<number>[]>([])
  const groups = async (): Promise<void> => setGroupOptions(await fetchGroups())
  useEffect(() => {
    groups()
  }, [])

  const filters: IFilters<IUser>[] = [
    {
      accessor: 'organisations',
      customLabel: 'organisation',
      options: orgOptions,
      value: defaultOrg?.value,
      placeholder: 'Select organisation...',
    },
    {
      accessor: 'status',
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
        { label: 'Orphaned', value: 'orphaned' },
      ],
      placeholder: 'Select status...',
      value: 'active',
    },
  ]

  const [tableState, setTableState] = useState<UserTableState>()
  const exportCsv = async (): Promise<void> => {
    const res = await exportUsers({ ...tableState })
    if (res) download(res, 'Users.csv')
  }

  return (
    <Page>
      <Card cardSize={CardSize.Full}>
        <CardHeader title="All users">
          <AsyncButton onClick={exportCsv}>Export CSV</AsyncButton>
          <Button
            buttonStyle={ButtonStyle.Action}
            onClick={(): void => history.push(`${Path.Users}/create`)}
          >
            Add user
          </Button>
        </CardHeader>
        <CardContent>
          <Table
            fetch={getUsers}
            filters={filters}
            columns={userColumns}
            search={true}
            rowLink={{ route: Path.Users, slug: 'userId' }}
            getState={setTableState}
            forgetful={Boolean(orgId)}
            options={{ orderBy: 'lastLogin', orderDirection: Order.Desc }}
            customFilter={{
              accessor: 'group',
              options: groupOptions,
              placeholder: 'Select group...',
            }}
          />
        </CardContent>
      </Card>
    </Page>
  )
}
