import { ISelectOption } from '@one-tree/library'
import { getGroups } from './APIhelper'

export const fetchGroups = async (): Promise<ISelectOption<number>[]> => {
  const res = await getGroups({ limit: 1000, skip: 0 })

  const options = res.data.map((group) => ({
    value: group.id,
    label: group.name,
  }))

  if (!res) throw new Error('Failed to fetch groups')

  return options
}
