import { hasRole, Role } from '@one-tree/library'
import React, { FunctionComponent, ReactElement } from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'
import { useAuth } from '../../context/AuthProvider'
import { useOrganisation } from '../../context/OrganisationProvider'
import { Path } from '../../types/routes'

export default function AuthRoute({
  component,
  path,
  requiredRole,
  exact,
}: {
  component: FunctionComponent
  path: string | string[]
  requiredRole: Role
  exact?: boolean
}): ReactElement {
  const { user } = useAuth()
  const { role } = user || {}

  const allowed = requiredRole ? hasRole(role, requiredRole) : true

  // localstorage can get cleared when accessing other apps on same domain
  // if this happens, we lose our orgs - need to re-authenticate
  // Note: Dispatch doesn't have orgs so not an issue for them
  const history = useHistory()
  const { organisations } = useOrganisation()
  if (!organisations.length && role !== Role.Dispatch) {
    history.push(Path.Logout)
  }

  return user && allowed ? (
    <Route exact={exact} path={path} component={component} />
  ) : (
    <Redirect
      to={{ pathname: Path.Login, state: { referer: document.referrer } }}
    />
  )
}
