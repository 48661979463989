import { OrganisationFormat, stripEmptyFromObject } from '@one-tree/library'
import styled from 'styled-components'
import {
  OrgStatus,
  PartialAdminOrg,
  PaymentMethod,
  PaymentProvider,
} from '../../types/orgTypes'

export const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
  h3 {
    margin: 35px 0 0;
  }
`
export const Column = styled.div`
  display: grid;
  grid-row-gap: 25px;
  grid-auto-rows: min-content;
`

export const HERO_DIMENSIONS = { x: 900, y: 300 }
export const LOGO_DIMENSIONS = { x: 200, y: 200 }

export const heroSubtitleText = 'The hero image appears at the top of the page, under the logo. It should be 900px by 300px.'
export const faviconText = 'The favicon should be a ".ico" file, at least 16px by 16px.'
export const logoText = 'The logo should be at least 200px on the smallest side.'
export const cssText = 'Use CSS to change elements of the page\'s styling, e.g. text colour or font.'

export type IEditOrgProps = {
  organisation: PartialAdminOrg
  change: (updates: PartialAdminOrg) => void
}

export const productMap = new Map<OrganisationFormat, string>([
  [OrganisationFormat.GiftVouchers, 'Gift vouchers'],
  [OrganisationFormat.SpecialOffers, 'Special offers'],
  [OrganisationFormat.Tickets, 'Tickets'],
])

export const statusMap = new Map<OrgStatus, string>([
  [OrgStatus.Active, 'Active'],
  [OrgStatus.Suspended, 'Suspended'],
  [OrgStatus.Cancelled, 'Cancelled'],
  [OrgStatus.Draft, 'Draft'],
])

export const paymentMap = new Map<PaymentMethod, string>([
  [PaymentMethod.Manual, 'Manual'],
  [PaymentMethod.GoCardless, 'GoCardless'],
])

export const stripUnnecessary = (org: PartialAdminOrg): PartialAdminOrg => {
  if (org.paymentProvider === PaymentProvider.Stripe) {
    return stripEmptyFromObject({
      ...org,
      paypalEmail: undefined,
      trustPassword: undefined,
      trustId: undefined,
    })
  }
  if (org.paymentProvider === PaymentProvider.PayPal) {
    return stripEmptyFromObject({
      ...org,
      stripePublicKey: undefined,
      stripeSecretKey: undefined,
      trustPassword: undefined,
      trustId: undefined,
    })
  }
  if (org.paymentProvider === PaymentProvider.Trust) {
    return stripEmptyFromObject({
      ...org,
      stripePublicKey: undefined,
      stripeSecretKey: undefined,
      paypalEmail: undefined,
    })
  }
  return org
}
