import {
  alertError,
  alertSuccess,
  AsyncButton,
  Button,
  ButtonStyle,
  capitalize,
  Card,
  CardContent,
  CardError,
  CardHeader,
  CardSize,
  ColorField,
  colors,
  ImageType,
  InputField,
  InputGroup,
  Loading,
  NumberField,
  onlyChanges,
  removeHash,
  shortVoucherWord,
  Switch,
  TextArea,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { OrgTitle } from '../../../components/DisplayOrganisation'
import { getHostedPage, patchHostedPage } from '../../../helpers/APIhelper'
import { Path } from '../../../types/routes'
import {
  cssText,
  faviconText,
  heroSubtitleText,
  HERO_DIMENSIONS,
} from '../OrgHelper'
import { getHostedErrors, isHostedValid } from '../HostedValidation'
import Page from '../../../components/Page'
import OrgTabs from '../OrgTabs'
import { IHostedPage } from '../../../types/orgTypes'
import { useOrganisation } from '../../../context/OrganisationProvider'
import {
  HostedAlignment,
  HostedFontFamily,
  HostedFontSize,
  HostedImageUpload,
  HostedTextArea,
} from './HostedHelper'

const Main = styled.div`
  display: grid;
  grid-row-gap: 25px;
  justify-items: flex-start;
  h3 {
    margin: 35px 0 0;
    &:first-child {
      margin-top: 0;
    }
  }
`
const Settings = styled.div`
  border: 1px solid ${colors.lightGray};
  border-radius: 4px;
  background-color: ${colors.lightestGray};
  padding: 0 20px;
  margin: 5px 0 25px;
  height: 60px;
  display: flex;
  align-items: center;
`

export default function OrgHosted(): ReactElement {
  const { id }: { id: string } = useParams()
  const [data, setData] = useState<IHostedPage>()
  const [changes, setChanges] = useState<Partial<IHostedPage>>()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const { organisations } = useOrganisation()
  const thisOrg = organisations.find((org) => org.id === Number(id))
  const { name, format } = thisOrg || {}

  const { state }: { state: { backLink?: Path } } = useLocation()
  const { backLink } = state || {}

  const fetchData = async (): Promise<void> => {
    setLoading(true)
    const res = await getHostedPage({ id })
    if (res) setData(res)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [id])

  const change = (updates: Partial<IHostedPage>): void => {
    setChanges((p) => ({ ...p, ...updates }))
  }

  const actualChanges = onlyChanges(data, changes)

  const saveHosted = async (): Promise<void> => {
    const patchObject = {
      id: Number(id),
      options: {
        ...actualChanges,
      },
    }

    // back end works on the IDs, the URLs are just for display
    delete patchObject.options.heroFile
    delete patchObject.options.faviconFile

    const res = await patchHostedPage(patchObject)

    if (res) {
      setChanges(undefined)
      setData(res)
      alertSuccess('Organisation saved')
    }
  }

  const errors = getHostedErrors({ ...data, ...changes })
  const invalid = !isHostedValid({ ...data, ...changes })

  const {
    bespoke,
    url,
    faviconFile,
    heroFile,
    hasHostedPage,
    privacyLink,
    css,
    topBarFontFamily,
    topBarFontSize,
    topBarFontColour,
    heroColour,
    heroText,
    heroFontFamily,
    heroFontSize,
    heroFontColour,
    mainBackgroundColour,
    mainHeaderFontFamily,
    mainHeaderFontColour,
    mainHeaderWidth,
    mainHeaderAlignment,
    mainHeaderText,
    mainParagraphText,
    mainParagraphFontFamily,
    mainParagraphFontColour,
    mainParagraphWidth,
    mainParagraphAlignment,
    footerFontFamily,
    footerFontSize,
    footerFontColour,
    footerBackgroundColour,
    termsAndConditions,
  } = { ...data, ...changes }

  const updateImage = (
    image: { id: number; url: string },
    idKey: keyof IHostedPage,
    urlKey: keyof IHostedPage,
  ): void => {
    setChanges((prevState) => ({
      ...prevState,
      [idKey]: image.id,
      [urlKey]: image.url,
    }))
  }

  // `hostedPageUrl` in different place in each URL, so single env variable doesn't work
  const HOSTED_URLS = {
    staging: `https://admin.one-tree.dev/hostedpage/${url}/`,
    production: `https://${url}.voucherdelivery.co.uk`,
  }

  const viewHostedPage = (): void => {
    if (!url) {
      alertError('No hosted page URL set')
      return
    }
    if (process.env.REACT_APP_API_HOST?.includes('one-tree.dev')) {
      window.open(HOSTED_URLS.staging, '_blank')
    } else {
      window.open(HOSTED_URLS.production, '_blank')
    }
  }

  const renderFields = hasHostedPage && !bespoke && (
    <Main>
      <h3>General</h3>
      <InputField
        label="URL subdirectory"
        subtitle="This will appear at the start of the hosted page's URL"
        value={url}
        onChange={(value): void => change({ url: value })}
      />
      <HostedImageUpload
        label="Favicon"
        imageType={ImageType.OrganisationHostedFavicon}
        imageFile={faviconFile}
        subtitle={faviconText}
        onSuccess={(response): void => {
          updateImage(response, 'favicon', 'faviconFile')
        }}
        onRemove={(): void => change({ favicon: null, faviconFile: null })}
        minimumAspect={{ x: 16, y: 16 }}
        noResizeOrConvert={true}
      />
      <h3>Top bar</h3>
      <InputGroup>
        <HostedFontFamily
          value={topBarFontFamily}
          onChange={(value): void => change({ topBarFontFamily: value })}
        />
        <HostedFontSize
          value={topBarFontSize}
          onChange={(value): void => change({ topBarFontSize: value })}
        />
        <ColorField
          label="Font colour"
          onChange={(value): void => change({ topBarFontColour: removeHash(value) })}
          value={topBarFontColour || ''}
        />
      </InputGroup>
      <h3>Hero banner</h3>
      <HostedImageUpload
        label="Image"
        imageType={ImageType.OrganisationHostedHero}
        imageFile={heroFile}
        subtitle={heroSubtitleText}
        cropPixelAspect={HERO_DIMENSIONS}
        onSuccess={(response): void => {
          updateImage(response, 'hero', 'heroFile')
        }}
        onRemove={(): void => change({ hero: null, heroFile: null })}
      />
      <InputGroup>
        <InputField
          label="Text"
          value={heroText}
          onChange={(value): void => change({ heroText: value })}
          subtitle="Shown over the banner"
          isLong={true}
          maxChars={50}
        />
        <ColorField
          label="Background colour"
          onChange={(value): void => change({ heroColour: removeHash(value) })}
          value={heroColour || ''}
          subtitle="Shown if no image provided"
        />
      </InputGroup>
      <InputGroup>
        <HostedFontFamily
          value={heroFontFamily}
          onChange={(value): void => change({ heroFontFamily: value })}
        />
        <HostedFontSize
          value={heroFontSize}
          onChange={(value): void => change({ heroFontSize: value })}
        />
        <ColorField
          label="Font colour"
          onChange={(value): void => change({ heroFontColour: removeHash(value) })}
          value={heroFontColour || ''}
        />
      </InputGroup>
      <h3>Main section</h3>
      <ColorField
        label="Background colour"
        onChange={(value): void => change({ mainBackgroundColour: removeHash(value) })}
        value={mainBackgroundColour || ''}
      />
      <h3>Main section header</h3>
      <HostedTextArea
        onChange={(value): void => change({ mainHeaderText: value })}
        value={mainHeaderText}
      />
      <InputGroup>
        <HostedFontFamily
          value={mainHeaderFontFamily}
          onChange={(value): void => change({ mainHeaderFontFamily: value })}
        />
        <ColorField
          label="Font colour"
          onChange={(value): void => change({ mainHeaderFontColour: removeHash(value) })}
          value={mainHeaderFontColour || ''}
        />
      </InputGroup>
      <InputGroup>
        <NumberField
          label="Width"
          placeholder="Width..."
          value={mainHeaderWidth || null}
          onChange={(value): void => change({ mainHeaderWidth: value || undefined })}
        />
        <HostedAlignment
          value={mainHeaderAlignment}
          onChange={(value): void => change({ mainHeaderAlignment: value })}
        />
      </InputGroup>
      <h3>Main section paragraph</h3>
      <HostedTextArea
        onChange={(value): void => change({ mainParagraphText: value })}
        value={mainParagraphText}
      />
      <InputGroup>
        <HostedFontFamily
          value={mainParagraphFontFamily}
          onChange={(value): void => change({ mainParagraphFontFamily: value })}
        />
        <ColorField
          label="Font colour"
          onChange={(value): void => change({ mainParagraphFontColour: removeHash(value) })}
          value={mainParagraphFontColour || ''}
        />
      </InputGroup>
      <InputGroup>
        <NumberField
          label="Width"
          placeholder="Width..."
          value={mainParagraphWidth || null}
          onChange={(value): void => change({ mainParagraphWidth: value || undefined })}
        />
        <HostedAlignment
          value={mainParagraphAlignment}
          onChange={(value): void => change({ mainParagraphAlignment: value })}
        />
      </InputGroup>
      <h3>Footer</h3>
      <InputGroup>
        <ColorField
          label="Background colour"
          onChange={(value): void => change({ footerBackgroundColour: removeHash(value) })}
          value={footerBackgroundColour || ''}
        />
        <ColorField
          label="Font colour"
          onChange={(value): void => change({ footerFontColour: removeHash(value) })}
          value={footerFontColour || ''}
        />
      </InputGroup>
      <InputGroup>
        <HostedFontFamily
          value={footerFontFamily}
          onChange={(value): void => change({ footerFontFamily: value })}
        />
        <HostedFontSize
          value={footerFontSize}
          onChange={(value): void => change({ footerFontSize: value })}
        />
      </InputGroup>
      <TextArea
        label={`${capitalize(shortVoucherWord(format))} terms and conditions`}
        value={termsAndConditions}
        disabled={true}
        style={{ minWidth: '580px' }}
        rows={8}
        subtitle="These terms are set in the 'Voucher template' area of the Portal."
      />
      <h3>Advanced</h3>
      <InputField
        label="Privacy link"
        onChange={(value): void => change({ privacyLink: value })}
        value={privacyLink}
        isLong={true}
      />
      <TextArea
        label="Custom CSS"
        rows={8}
        subtitle={cssText}
        onChange={(value): void => change({ css: value })}
        value={css}
        style={{ minWidth: '580px' }}
      />
    </Main>
  )

  return (
    <Page warnBeforeExit={Boolean(actualChanges)}>
      <Card cardSize={CardSize.Medium}>
        <OrgTabs pathId={id} />
        {loading || !data || !id ? (
          <Loading fullPage={true} />
        ) : (
          <>
            <CardHeader title={<OrgTitle name={name} format={format} />}>
              <Button
                buttonStyle={ButtonStyle.Secondary}
                onClick={(): void => history.push(backLink || Path.Organisations)}
              >
                Cancel
              </Button>
              <AsyncButton
                buttonStyle={ButtonStyle.Action}
                disabled={!actualChanges}
                onClick={saveHosted}
                showConfirmation={invalid}
                confirmationMessage={
                  invalid ? 'There are errors. Save anyway?' : ''
                }
              >
                Save
              </AsyncButton>
            </CardHeader>
            <CardError>{errors[0]}</CardError>
            <CardContent>
              <Settings>
                <InputGroup>
                  <Switch
                    label={
                      bespoke ? 'Has bespoke hosted page' : 'Has hosted page'
                    }
                    value={hasHostedPage || false}
                    switchAction={(value): void => change({ hasHostedPage: value })}
                    flipLabel={true}
                    disabled={bespoke}
                  />
                  {hasHostedPage && (
                    <Button
                      onClick={viewHostedPage}
                      disabled={Boolean(actualChanges)}
                    >
                      View hosted page
                    </Button>
                  )}
                </InputGroup>
              </Settings>
              {renderFields}
            </CardContent>
          </>
        )}
      </Card>
    </Page>
  )
}
