import React, { ReactElement, ReactNode } from 'react'
import { colors, Page as PageComponent } from '@one-tree/library'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../assets/logo-header.svg'
import { useAuth } from '../context/AuthProvider'
import SideMenu from './SideMenu'
import Breadcrumbs from './Breadcrumbs'

const StyledLogo = styled(Logo)`
  width: 165px;
  height: auto;
  fill: ${colors.white};
`
interface IPageProps {
  children: ReactNode
  warnBeforeExit?: boolean
}
export default function Page(props: IPageProps): ReactElement | null {
  const { children, warnBeforeExit } = props
  const { user } = useAuth()

  return user ? (
    <PageComponent
      warnBeforeExit={warnBeforeExit}
      primaryColor={colors.lightGreen}
      secondaryColor={colors.green}
      user={user}
      menuContent={<SideMenu />}
      logo={<StyledLogo />}
      breadcrumbs={<Breadcrumbs />}
      env={process.env.REACT_APP_API_HOST}
    >
      {children}
    </PageComponent>
  ) : null
}
