import {
  alertSuccess,
  AsyncButton,
  Button,
  ButtonStyle,
  colors,
  enumToOptions,
  InputGroup,
  Modal,
  OrgRole,
  SelectField,
} from '@one-tree/library'
import React, { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { deleteOrgFromUser, patchUser } from '../../helpers/APIhelper'
import { IUserOrg, PartialUser } from '../../types/userTypes'
import { orgRoleMap } from '../users/UsersHelper'

const ModalContent = styled.div`
  display: grid;
  grid-row-gap: 10px;
  min-width: 300px;
  h3 {
    margin: 0 0 10px 0;
    strong {
      color: ${colors.blue};
    }
  }
`
const StyledInputGroup = styled(InputGroup)`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`
interface IProps {
  editOrgModal?: number
  setEditOrgModal: (value?: number) => void
  user: PartialUser
  editingOrg: IUserOrg
}
export default function EditUserOrg(props: IProps): ReactElement {
  const {
    editOrgModal, setEditOrgModal, user, editingOrg,
  } = props

  const close = (): void => setEditOrgModal(undefined)

  const { role } = editingOrg
  const [editRole, setEditRole] = useState<OrgRole>(role)

  const removeOrg = async (): Promise<void> => {
    if (user.userId && editOrgModal) {
      const res = await deleteOrgFromUser({
        orgId: editOrgModal,
        userId: user.userId,
      })
      if (res) {
        alertSuccess('Organisation removed')
        close()
      }
    }
  }

  const saveOrg = async (): Promise<void> => {
    if (user.userId && user.organisations) {
      const orgIndex = user.organisations.findIndex(
        ({ id }) => id === editOrgModal,
      )

      if (orgIndex || orgIndex === 0) {
        const newOrgs = [...user.organisations]

        newOrgs[orgIndex] = {
          ...newOrgs[orgIndex],
          role: editRole,
        }

        const res = await patchUser({
          id: user.userId,
          options: {
            emailAddress: user.emailAddress,
            organisations: newOrgs,
          },
        })

        if (res) {
          alertSuccess('Organisation updated')
          close()
        }
      }
    }
  }

  return (
    <Modal modalOpen={Boolean(editOrgModal)} onModalClose={close}>
      <ModalContent>
        <h3>
          Editing: <strong>{editingOrg.name}</strong>
        </h3>
        <SelectField
          placeholder="Select role..."
          label="Role"
          options={enumToOptions(OrgRole)}
          onChange={(option): void => {
            if (option) setEditRole(option?.value)
          }}
          value={
            editRole
              ? { value: editRole, label: orgRoleMap.get(editRole) }
              : undefined
          }
        />
        <StyledInputGroup>
          <Button buttonStyle={ButtonStyle.Secondary} onClick={close}>
            Cancel
          </Button>
          <AsyncButton
            buttonStyle={ButtonStyle.Danger}
            onClick={removeOrg}
            showConfirmation={true}
          >
            Remove
          </AsyncButton>
          <AsyncButton buttonStyle={ButtonStyle.Action} onClick={saveOrg}>
            Save
          </AsyncButton>
        </StyledInputGroup>
      </ModalContent>
    </Modal>
  )
}
