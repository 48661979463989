import { colors, IFiltered, OrganisationFormat } from '@one-tree/library'
import React, { ReactElement } from 'react'
import styled from 'styled-components'

export const ClickOrg = styled.div`
  :hover {
    :after {
      content: '';
      position: absolute;
      inset: 0;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.14);
      cursor: pointer;
    }
  }
`

const FilterString = styled.span`
  color: ${colors.gray};
`
export function TableTitle(props: {
  title: string
  filtered?: IFiltered
}): ReactElement {
  const { title, filtered } = props

  const string = ` (${filtered?.recordsFiltered} filtered from ${filtered?.recordsTotal} total)`

  return (
    <div>
      {title}
      <FilterString>{string}</FilterString>
    </div>
  )
}

export const productOptions = [
  {
    value: OrganisationFormat.GiftVouchers,
    label: 'Gift vouchers',
  },
  {
    value: OrganisationFormat.Tickets,
    label: 'Tickets',
  },
  {
    value: OrganisationFormat.SpecialOffers,
    label: 'Special offers',
  },
]
