import EmailValidator from 'email-validator'
import { PartialUser } from '../../types/userTypes'

// TODO what is required for a user?

export const userEmailTest = (user?: PartialUser): string | false => {
  const { emailAddress } = user || {}
  if (!emailAddress) return 'Email required'
  if (emailAddress && !EmailValidator.validate(emailAddress)) {
    return 'Email invalid'
  }
  return false
}

export const userNameTest = (user?: PartialUser): string | false => {
  const { firstName, lastName } = user || {}
  if (!firstName) return 'First name required'
  if (!lastName) return 'Last name required'
  return false
}

export const getUserErrors = (user?: PartialUser): string[] => {
  const errorArray: string[] = []

  const add = (errorTest: (user?: PartialUser) => string | false): void => {
    if (!user) errorArray.push('Can\'t find user')

    const error = errorTest(user)
    if (error) errorArray.push(error)
  }

  add(userEmailTest)
  add(userNameTest)

  return errorArray
}

export const isUserValid = (user?: PartialUser): boolean => {
  if (getUserErrors(user).length) return false
  return true
}
