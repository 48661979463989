import {
  alertSuccess,
  AsyncButton,
  Button,
  ButtonStyle,
  InputField,
  InputGroup,
  Modal,
  ModalState,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { createGroup, patchGroup } from '../../helpers/APIhelper'
import { IGroup } from '../../types/orgTypes'

const ModalContent = styled.div`
  display: grid;
  grid-row-gap: 10px;
  width: 250px;
  h3 {
    margin: 0 0 10px 0;
  }
`
const StyledInputGroup = styled(InputGroup)`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`
interface IProps {
  group?: IGroup
  useModal: ModalState
}
export default function EditOrgGroups(props: IProps): ReactElement | null {
  const {
    group,
    useModal: [modal, close],
  } = props
  const [name, setName] = useState('')

  const save = async (): Promise<void> => {
    if (group) {
      const res = await patchGroup({
        id: group.id,
        name,
      })
      if (res) {
        alertSuccess('Group saved')
        close()
      }
    } else {
      const res = await createGroup({
        name,
      })
      if (res) {
        alertSuccess('Group created')
        close()
      }
    }
  }

  useEffect(() => {
    if (group) setName(group.name)
  }, [group])

  return (
    <Modal modalOpen={modal} onModalClose={close}>
      <ModalContent>
        <h3>{group?.id ? 'Edit group' : 'Add group'}</h3>
        <InputField placeholder="Group name" value={name} onChange={setName} />
        <StyledInputGroup>
          <Button buttonStyle={ButtonStyle.Secondary} onClick={close}>
            Cancel
          </Button>
          <AsyncButton buttonStyle={ButtonStyle.Action} onClick={save}>
            Save
          </AsyncButton>
        </StyledInputGroup>
      </ModalContent>
    </Modal>
  )
}
