import {
  Role,
  IHistoricData,
  OrganisationFormat,
  ReportMetric,
} from '@one-tree/library'

export enum Resource {
  Authenticate = '/authenticate',
  RequestPassword = '/authenticate/request-password',
  ChangePassword = '/authenticate/change-password',
  RefreshToken = '/authenticate/refresh-token',
  Sales = '/admin/sales',
  Pending = '/admin/orders/pending',
  Organisations = '/admin/organisations',
  Performance = '/admin/organisations/performance',
  ReportingOverview = '/admin/reporting/overview',
  ReportingSummary = '/admin/reporting/summary',
  MiniGraphs = '/admin/reporting/sales',
  Rankings = '/admin/reporting/rankings',
  GlobalSettings = '/admin/settings',
  Upload = '/upload',
  Invoices = '/admin/invoices',
  Users = '/admin/users',
  Dispatch = '/admin/dispatches',
  Masquerade = '/admin/organisations/login-as',
  Groups = '/admin/groups',
  Types = '/metadata/organisation-types',
}

export interface ITokenPair {
  token: string
  refreshToken?: string
}

export interface IDecodedToken {
  aud: string
  email: string
  exp: number
  firstName: string
  iat: number
  id: string
  iss: string
  lastName: string
  nbf: number
  role: Role
  sub: string
}

export interface IReportingOverviewData {
  data: IHistoricData[]
  commission: number
}

export enum ReportingSummaryCurrency {
  GBP = '1',
  EUR = '2',
}
export enum ReportingSummaryInterval {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}
type SummaryProduct = {
  count: number
  totalValue: number
  totalCommission: number
}
export interface IReportingSummaryData {
  gv: SummaryProduct
  te: SummaryProduct
  so: SummaryProduct
  total: SummaryProduct
}

type PointArray = { x: string; y: number }[]
type GraphBlock = {
  value: PointArray
  volume: PointArray
  commission: PointArray
}
export interface IReportingMiniGraphData {
  total: GraphBlock
  voucher: GraphBlock
  offer: GraphBlock
  ticket: GraphBlock
}
export enum MiniGraphMetricType {
  Value = 'value',
  Volume = 'volume',
  Commission = 'commission',
}

export type RankingsItem = {
  name: string
  value: number
  id: number
}
export enum RankingsMetricType {
  Value = 'value',
  Volume = 'volume',
  Invoiced = 'invoiced',
}
export interface IReportingRankingsData {
  bestPerforming: RankingsItem[]
  mostImproved: RankingsItem[]
  worstPerforming: RankingsItem[]
  leastImproved: RankingsItem[]
}
export interface IOverviewFetchParams {
  fromMonth: number
  fromYear: number
  format: OrganisationFormat | undefined
  organisationId: number | undefined
  metric: ReportMetric
}

export type OrgGroup = {
  id: number
  name: string
}
export interface IGlobalSettings {
  firstClassDelivery: number
  firstClassDeliveryInfo: string
  postageMessage: string
  groups: OrgGroup[]
  vat: number
  invoiceMinimum: number
  splashBackgroundId: number
  splashBackgroundUrl: string
  splashForegroundId: number
  splashForegroundUrl: string
  splashLink: string
  hostedDeliveryInformation: string
  hostedEmailDeliveryTerms: string
  hostedPostDeliveryTerms: string
}
