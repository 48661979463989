import {
  AsyncButton,
  Buttonlink,
  colors,
  Hyperlink,
  InputField,
  InputType,
  Role,
  validateEmail,
  validatePassword,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import {
  Link, Route, Switch, useHistory,
} from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as OneTreeLogo } from '../../assets/logo.svg'
import { useAuth } from '../../context/AuthProvider'
import { useOrganisation } from '../../context/OrganisationProvider'
import {
  authenticate,
  changePassword,
  getOrganisations,
  getTypes,
  requestPassword,
} from '../../helpers/APIhelper'
import { Path } from '../../types/routes'
import { useError } from './loginHelper'

const Styles = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`
const Form = styled.form`
  margin: auto;
  padding: 20px;
  min-width: 200px;
  max-width: 300px;
  width: -webkit-fill-available;
  height: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  p {
    margin-bottom: 0;
  }
`
const Logo = styled(OneTreeLogo)`
  fill: ${colors.logo};
`
const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`
const Controls = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`
const Error = styled.div`
  font-weight: bold;
  color: red;
`
const Message = styled.div`
  font-weight: bold;
  color: green;
`
const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
  margin-bottom: 30px;
`

export default function Login(): ReactElement {
  const {
    errors, showErrors, addError, clearErrors, hasErrors,
  } = useError()

  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')

  const history = useHistory()
  const { saveAccessToken, clearAuth } = useAuth()
  const { saveOrganisations, saveTypes } = useOrganisation()

  const clearAll = (): void => {
    clearErrors()
    setMessage('')
  }
  useEffect(() => clearAll(), [email, password, passwordRepeat])

  const handleLogin = async (): Promise<void> => {
    // if we are logged in in another tab, log out first
    clearAuth()

    const emailError = validateEmail(email)
    const passwordError = validatePassword(password)

    if (emailError) {
      addError(emailError)
      return
    }
    if (passwordError) {
      addError(passwordError)
      return
    }

    const token = await authenticate({ email, password })
    if (!token) return

    const role = saveAccessToken(token)

    if (role !== Role.Dispatch) {
      saveOrganisations(await getOrganisations())
      saveTypes(await getTypes())
    }

    if (role === Role.SuperAdmin) {
      history.push(Path.Reporting, 'animate')
    } else if (role === Role.SubSuperAdmin) {
      history.push(Path.Sales)
    } else if (role === Role.Dispatch) {
      history.push(Path.Dispatch)
    } else {
      history.push(Path.Logout)
    }
  }

  const renderLogin = (
    <>
      <p>Welcome back!</p>
      <Inputs>
        <InputField
          placeholder="Email address"
          type={InputType.Email}
          onChange={setEmail}
          isError={showErrors && !email}
        />
        <InputField
          placeholder="Password"
          type={InputType.Password}
          onChange={setPassword}
          isError={showErrors && !password}
        />
      </Inputs>
      <Controls>
        <AsyncButton type="submit" onClick={handleLogin}>
          Login
        </AsyncButton>
        <Link to={Path.Request}>
          <Buttonlink onClick={clearAll}>Reset password</Buttonlink>
        </Link>
      </Controls>
    </>
  )

  const handleRequest = async (): Promise<void> => {
    const emailError = validateEmail(email)

    if (emailError) {
      addError(emailError)
      return
    }

    if (await requestPassword({ email })) {
      setMessage('Check your emails')
      return
    }

    addError('User not found')
  }

  const renderRequest = (
    <>
      <Inputs>
        <InputField
          placeholder="Email address"
          type={InputType.Email}
          onChange={setEmail}
          isError={showErrors && !email}
        />
      </Inputs>
      <Controls>
        <AsyncButton type="submit" onClick={handleRequest}>
          Send reset email
        </AsyncButton>
        <Link to={Path.Login}>
          <Buttonlink onClick={clearAll}>Back to login</Buttonlink>
        </Link>
      </Controls>
    </>
  )

  const handleChange = async (): Promise<void> => {
    const passwordError = validatePassword(password)

    if (passwordError) {
      addError(passwordError)
      return
    }

    if (password !== passwordRepeat) {
      addError('Passwords need to match')
      return
    }

    const hash = history.location.search.slice(1)
    if (!hash) {
      addError('Link broken, send another request')
      return
    }

    if (await changePassword({ hash, password, passwordRepeat })) {
      setMessage('Password changed')
      history.push(Path.Login)
    } else {
      addError('Error changing password')
    }
  }

  const renderChange = (
    <>
      <Inputs>
        <InputField
          placeholder="Password"
          type={InputType.Password}
          onChange={setPassword}
          isError={showErrors && !password}
        />
        <InputField
          placeholder="Repeat password"
          type={InputType.Password}
          onChange={setPasswordRepeat}
          isError={showErrors && !passwordRepeat}
        />
      </Inputs>
      <Controls>
        <AsyncButton type="submit" onClick={handleChange}>
          Change password
        </AsyncButton>
        <Link to={Path.Login}>
          <Buttonlink onClick={clearAll}>Back to login</Buttonlink>
        </Link>
      </Controls>
    </>
  )

  return (
    <Styles>
      <Form
        noValidate={true}
        onSubmit={(event): void => event.preventDefault()}
      >
        <Logo />
        <Switch>
          <Route
            path={Path.Request}
            render={(): ReactElement => renderRequest}
          />
          <Route path={Path.Change} render={(): ReactElement => renderChange} />
          <Route path={Path.Login} render={(): ReactElement => renderLogin} />
        </Switch>
        {hasErrors && <Error>{errors[0]}</Error>}
        {message && <Message>{message}</Message>}
      </Form>
      <Links>
        <Hyperlink href="tel:01761472911">01761 472911</Hyperlink>
        <Hyperlink href="mailto:support@one-tree.net">
          support@one-tree.net
        </Hyperlink>
      </Links>
    </Styles>
  )
}
