import {
  alertSuccess,
  AsyncButton,
  ButtonStyle,
  Card,
  CardContent,
  CardHeader,
  CardSize,
  InputField,
  InputGroup,
  ITableData,
  ITableRequest,
  Order,
  Table,
} from '@one-tree/library'
import React, { ReactElement, useState } from 'react'
import styled from 'styled-components'
import Page from '../../components/Page'
import {
  addVoucherToDispatch,
  getDispatch,
  getVoucherMaterials,
  markVouchersDispatched,
} from '../../helpers/APIhelper'
import { IDispatch } from '../../types/adminTypes'
import { getDispatchColumns, isId, isNumber } from './DispatchHelper'

const ControlGroup = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-row-gap: 10px;
`
export default function Dispatch(): ReactElement {
  const [data, setData] = useState<IDispatch[]>([])
  const [selected, setSelected] = useState<number[]>([])
  const [voucherId, setVoucherId] = useState('')
  const allSelected = selected.length === data.length && data.length > 0

  const select = (id: number): void => {
    setSelected((prevState) => {
      const rowIndex = prevState.findIndex((index) => index === id)
      const newData = [...prevState]
      if (rowIndex === -1) {
        newData.push(id)
      } else {
        newData.splice(rowIndex, 1)
      }
      return newData
    })
  }

  const selectAll = (): void => {
    setSelected(() => {
      if (allSelected) return []
      return data.map(({ id }) => id)
    })
  }

  const dispatchColumns = getDispatchColumns(
    selected,
    select,
    selectAll,
    allSelected,
  )

  const [fetchTable, setFetchTable] = useState(false)

  const addVoucher = async (): Promise<void> => {
    if (isId(voucherId)) {
      const res = await addVoucherToDispatch({ voucherId })
      if (res) {
        alertSuccess('Voucher added')
        setVoucherId('')
        setFetchTable((prevState) => !prevState)
      }
    }
  }

  const exportData = async (): Promise<void> => {
    if (selected.length) {
      const res = await getVoucherMaterials({ export: selected })
      if (res) window.open(res.filename, '_blank')
      setFetchTable((prevState) => !prevState)
    }
  }

  const markDispatched = async (): Promise<void> => {
    if (selected.length) {
      const res = await markVouchersDispatched({ voucherIds: selected })
      if (res) alertSuccess(`${selected.length} marked as dispatched.`)
      setSelected([])
      setFetchTable((prevState) => !prevState)
    }
  }

  const noExportDispatch = data
    .filter((each) => selected.includes(each.id))
    .filter(({ exported }) => !exported)
  const exportWarning = noExportDispatch.length > 0
  const warningPlural = noExportDispatch.length > 1 ? 's have' : ' has'

  const noExportMessage = `${noExportDispatch.length} selected voucher${warningPlural} not been exported. Are you sure you want to mark dispatched?`

  const getDispatchAndReset = async (
    fetchData: ITableRequest<IDispatch>,
  ): Promise<ITableData<IDispatch>> => {
    const res = await getDispatch(fetchData)
    setSelected([])
    return res
  }

  return (
    <Page>
      <Card cardSize={CardSize.Full}>
        <CardHeader title="Dispatch">
          <ControlGroup>
            <InputField
              placeholder="Voucher ID..."
              value={voucherId}
              onChange={(value): void => {
                if (isNumber(value) && value.length <= 7) setVoucherId(value)
              }}
            />
            <AsyncButton onClick={addVoucher} disabled={!isId(voucherId)}>
              Add voucher
            </AsyncButton>
          </ControlGroup>
        </CardHeader>
        <CardContent>
          <Table
            fetch={getDispatchAndReset}
            columns={dispatchColumns}
            search={true}
            options={{
              limit: 50,
              orderBy: 'dispatchDate',
              orderDirection: Order.Asc,
            }}
            controls={(
              <InputGroup>
                <AsyncButton
                  buttonStyle={ButtonStyle.Action}
                  onClick={exportData}
                  disabled={!selected.length}
                >
                  Export data
                </AsyncButton>
                <AsyncButton
                  buttonStyle={
                    exportWarning ? ButtonStyle.Secondary : ButtonStyle.Action
                  }
                  onClick={markDispatched}
                  disabled={!selected.length}
                  showConfirmation={exportWarning}
                  confirmationMessage={noExportMessage}
                >
                  Mark dispatched
                </AsyncButton>
              </InputGroup>
            )}
            getData={setData}
            reset={fetchTable}
          />
        </CardContent>
      </Card>
    </Page>
  )
}
