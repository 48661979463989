import {
  Card,
  CardContent,
  CardHeader,
  CardSize,
  colors,
  enumKeyByValue,
  enumToOptions,
  IGraphData,
  InputGroup,
  RangeSelector,
  SelectField,
  useDebounce,
} from '@one-tree/library'
import moment from 'moment'
import React, {
  ReactElement, useEffect, useMemo, useState,
} from 'react'
import {
  AxisOptions, AxisTimeOptions, Chart, UserSerie,
} from 'react-charts'
import styled from 'styled-components'
import { getReportingMiniGraphs } from '../../helpers/APIhelper'
import {
  IReportingMiniGraphData,
  MiniGraphMetricType,
} from '../../types/APItypes'
import FadeIn from './FadeIn'

const Graphs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`
const GraphWrapper = styled.div`
  min-height: 250px;
  flex: 1;
  margin: 20px 10px;
  .tick {
    line {
      display: none;
    }
    text {
      /* needed to override react-charts inline styles  */
      font-size: 0.9rem !important;
    }
  }
  .tickLabel {
    white-space: pre;
  }
`
const GraphTitle = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${({ theme }): string => theme.color};
  margin: 0 0 5px 10px;
`

export default function MiniGraphs(): ReactElement {
  const [data, setData] = useState<IReportingMiniGraphData>()
  const [metricType, setMetricType] = useState(MiniGraphMetricType.Value)

  const [loading, setLoading] = useState(false)

  const initFromDate = moment().subtract(1, 'week').startOf('day')
  const [fromDate, setFromDate] = useState(initFromDate)

  const initToDate = moment().endOf('day')
  const [toDate, setToDate] = useState(initToDate)

  const fetchData = async (): Promise<void> => {
    setLoading(true)
    const res = await getReportingMiniGraphs({
      dataType: metricType,
      fromDate: Math.floor(fromDate.valueOf() / 1000),
      toDate: Math.floor(toDate.valueOf() / 1000),
    })
    if (res) setData(res)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [useDebounce(fromDate, 500), useDebounce(toDate, 500)])

  const xAxis = useMemo(
    (): AxisTimeOptions<IGraphData> => ({
      getValue: (datum: IGraphData): Date => {
        const { label } = datum

        // have to do this, as the library crashes unless the label is a date
        if (label === 'loading') return new Date()

        const formattedLabel = label.split('-').reverse().join('-')
        const date = new Date(formattedLabel)
        return date
      },
      formatters: {
        scale: (value: Date | null): string => {
          if (!value) return ''

          const formatter = new Intl.DateTimeFormat('en-GB', {
            day: 'numeric',
            month: 'short',
          })

          return formatter.format(value).replaceAll('/', '-')
        },
      },
    }),
    [],
  )

  const yAxis = useMemo(
    (): AxisOptions<IGraphData> => ({
      min: 0,
      elementType: 'line',
      getValue: (datum: IGraphData): number => datum.total || 0,
      formatters: {
        scale: (value: string): string => {
          if (!value) return '£0'

          const formattedValue = value.toLocaleString()

          if (metricType !== MiniGraphMetricType.Volume) {
            return `£${formattedValue}`
          }
          return formattedValue
        },
      },
    }),
    [metricType],
  )

  const getData = (
    type: keyof IReportingMiniGraphData,
  ): UserSerie<IGraphData>[] => {
    const hasData = data
      && data[type]
      && data[type][metricType]
      && data[type][metricType].length > 0

    return [
      {
        label: '',
        data: hasData
          ? data[type][metricType].map((bin) => ({
            label: bin.x,
            total: bin.y,
          }))
          : [{ label: 'loading', total: 0 }],
      },
    ]
  }

  interface IGraphType {
    key: keyof IReportingMiniGraphData
    name: string
    color: string
  }
  const graphTypes: IGraphType[] = [
    {
      key: 'voucher',
      name: `Gift voucher ${metricType}`,
      color: colors.giftVouchers,
    },
    { key: 'total', name: `Total ${metricType}`, color: colors.black },
    {
      key: 'offer',
      name: `Special offer ${metricType}`,
      color: colors.specialOffers,
    },
    { key: 'ticket', name: `Ticket ${metricType}`, color: colors.tickets },
  ]

  const renderGraphs = graphTypes.map(({ key, color, name }, index) => {
    const indexKey = `${index}`

    return (
      <GraphWrapper key={indexKey}>
        <GraphTitle theme={{ color }}>{name}</GraphTitle>
        <Chart
          options={{
            data: getData(key),
            primaryAxis: xAxis,
            secondaryAxes: [yAxis],
            defaultColors: [color],
          }}
        />
      </GraphWrapper>
    )
  })

  return (
    <Card cardSize={CardSize.Full}>
      <CardHeader title="Sales graphs" />
      <CardContent>
        <InputGroup>
          <SelectField
            label="Data type"
            options={enumToOptions(MiniGraphMetricType)}
            value={{
              value: metricType,
              label: enumKeyByValue(MiniGraphMetricType, metricType),
            }}
            onChange={(option): void => {
              if (option) setMetricType(option.value)
            }}
          />
          <RangeSelector
            fromState={[fromDate, setFromDate]}
            toState={[toDate, setToDate]}
          />
        </InputGroup>
        <FadeIn ready={!loading} style={{ minHeight: '240px' }}>
          <Graphs>{renderGraphs}</Graphs>
        </FadeIn>
      </CardContent>
    </Card>
  )
}
