import {
  alertSuccess,
  AsyncButton,
  Button,
  ButtonStyle,
  CardHeader,
  onlyChanges,
} from '@one-tree/library'
import React, { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { OrgTitle } from '../../components/DisplayOrganisation'
import { deleteOrganisation } from '../../helpers/APIhelper'
import { PartialAdminOrg } from '../../types/orgTypes'
import { Path } from '../../types/routes'
import OrgClone from './OrgClone'
import { activateError, isOrgValid } from './OrgValidation'

const Header = styled(CardHeader)`
  position: relative;
`
interface IOrgHeaderProps {
  organisation?: PartialAdminOrg
  changes?: PartialAdminOrg
  saveOrg: () => void
  backLink?: Path
}
export default function OrgHeader(props: IOrgHeaderProps): ReactElement {
  const {
    organisation, changes, saveOrg, backLink,
  } = props
  const { id, name, format } = organisation || {}

  const history = useHistory()
  const invalid = !isOrgValid({ ...organisation, ...changes })
  const cannotActivate = activateError({ ...organisation, ...changes })

  const actualChanges = onlyChanges(organisation, changes)

  const [cloneModal, setCloneModal] = useState(false)

  const handleDelete = async (): Promise<void> => {
    if (!id) return
    const res = await deleteOrganisation({ id })
    if (res) {
      alertSuccess('Organisation deleted')
      history.push(Path.Organisations)
    }
  }

  return (
    <>
      <Header title={<OrgTitle name={name} format={format} />}>
        {organisation?.id && (
          <>
            <Button
              onClick={(): void => history.push(`${Path.Masquerade}/${organisation.id}`)}
            >
              Portal
            </Button>
            <AsyncButton onClick={(): void => setCloneModal(true)}>
              Clone
            </AsyncButton>
            <AsyncButton
              buttonStyle={ButtonStyle.Danger}
              showConfirmation={true}
              onClick={handleDelete}
              confirmationMessage={`Are you sure you want to permanently delete the organisation ${name}?`}
            >
              Delete
            </AsyncButton>
          </>
        )}
        <Button
          buttonStyle={ButtonStyle.Secondary}
          onClick={(): void => history.push(backLink || Path.Organisations)}
        >
          Cancel
        </Button>
        <AsyncButton
          buttonStyle={ButtonStyle.Action}
          disabled={!actualChanges || cannotActivate}
          onClick={saveOrg}
          showConfirmation={invalid}
          confirmationMessage={
            invalid ? 'There are errors. Save anyway as draft?' : ''
          }
        >
          Save
        </AsyncButton>
      </Header>
      <OrgClone
        id={organisation?.id}
        modalOpen={cloneModal}
        modalClose={(): void => setCloneModal(false)}
      />
    </>
  )
}
