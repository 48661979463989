import { useState } from 'react'

interface IUseError {
  errors: string[]
  showErrors: boolean
  addError: (message: string) => void
  clearErrors: () => void
  hasErrors: boolean
}
export const useError = (): IUseError => {
  const [errors, setErrors] = useState<string[]>([])
  const [showErrors, setShowErrors] = useState(false)

  const addError = (message: string): void => {
    setErrors((prevState) => [...prevState, message])
    setShowErrors(true)
  }

  const clearErrors = (): void => {
    setErrors([])
    setShowErrors(false)
  }

  const hasErrors = errors.length > 0

  return {
    errors,
    showErrors,
    addError,
    clearErrors,
    hasErrors,
  }
}
