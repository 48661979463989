export enum Path {
  Change = '/login/change',
  Dispatch = '/dispatch',
  InvoiceSettings = '/invoices/settings',
  Invoices = '/invoices',
  Login = '/login',
  Logout = '/logout',
  Masquerade = '/masquerade',
  OrgFinance = '/organisations/:id/finance',
  OrgHosted = '/organisations/:id/hosted',
  OrgSettings = '/organisations/settings',
  OrgGroups = '/organisations/groups',
  Organisations = '/organisations',
  Pending = '/sales/pending',
  Performance = '/performance',
  Reporting = '/reporting',
  Request = '/login/request',
  Sales = '/sales',
  Users = '/users',
}
