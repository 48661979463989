import {
  alertSuccess,
  Card,
  CardError,
  CardSize,
  CurrencyCode,
  Loading,
  onlyChanges,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom'
import styled from 'styled-components'
import Page from '../../components/Page'
import { useOrganisation } from '../../context/OrganisationProvider'
import {
  createOrganisation,
  getOrganisation,
  getOrganisations,
  patchOrganisation,
} from '../../helpers/APIhelper'
import {
  OrgStatus,
  PartialAdminOrg,
  PaymentProvider,
  Size,
} from '../../types/orgTypes'
import { Path } from '../../types/routes'
import OrgDetails from './OrgDetails'
import OrgFinance from './OrgFinance'
import OrgHeader from './OrgHeader'
import { stripUnnecessary } from './OrgHelper'
import OrgTabs from './OrgTabs'
import { getOrgErrors, isOrgValid, getActivationError } from './OrgValidation'

const Error = styled.div`
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export default function Organisation(): ReactElement {
  const { id }: { id: string } = useParams()
  const [loading, setLoading] = useState(true)
  const [organisation, setOrganisation] = useState<PartialAdminOrg>()
  const [changes, setChanges] = useState<PartialAdminOrg>()
  const [status, setStatus] = useState<OrgStatus>()
  const { saveOrganisations } = useOrganisation()

  const errors = getOrgErrors({ ...organisation, ...changes })
  const invalid = !isOrgValid({ ...organisation, ...changes })

  const cannotActivateReason = getActivationError({
    ...organisation,
    ...changes,
  })
  const cannotActivateMessage = cannotActivateReason
    && `Cannot activate: missing '${cannotActivateReason}'. Please set in Portal.`

  const fetchData = async (): Promise<void> => {
    setLoading(true)
    if (id === 'create') {
      setOrganisation({
        status: OrgStatus.Draft,
        size: Size.A4,
        paymentProvider: PaymentProvider.Stripe,
        currencyCode: CurrencyCode.GBP,
      })
    } else {
      const res = await getOrganisation({ id })
      if (res && res.id) {
        setOrganisation(res)
        setStatus(res.status)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [id])

  const history = useHistory()

  const actualChanges = onlyChanges(organisation, changes)

  const saveOrg = async (): Promise<void> => {
    if (organisation?.id && actualChanges) {
      const res = await patchOrganisation({
        id: organisation.id,
        options: stripUnnecessary(actualChanges),
      })

      if (res) {
        setChanges(undefined)
        alertSuccess('Organisation saved')
        setOrganisation(res)
        setStatus(res.status)
        saveOrganisations(await getOrganisations())
      }
    } else if (organisation) {
      const data = stripUnnecessary({
        ...organisation,
        ...changes,
      })
      const res = await createOrganisation(data)

      if (res) {
        setChanges(undefined)
        alertSuccess('New organisation created')
        history.push(`${Path.Organisations}/${res.id}`)
      }
    }
  }

  const change = (updates: PartialAdminOrg): void => {
    setChanges((p) => {
      if (updates.status) setStatus(updates.status)
      return { ...p, ...updates }
    })
  }
  const orgPath = `${Path.Organisations}/${id}`

  // if org becomes invalid, set status to `Draft`. Otherwise, revert to previous status.
  useEffect(() => {
    if (organisation && invalid) {
      // not use `change` so as not to save this status
      setChanges((p) => ({ ...p, status: OrgStatus.Draft }))
    } else if (changes?.status && status) {
      change({ status })
    }
  }, [invalid])

  // Save backLink from prev page. If navigate between tabs, want to keep backLink,
  // so need to write to state only if exists.
  const { state }: { state: { backLink?: Path } } = useLocation()
  const { backLink } = state || {}
  const [savedBackLink, setSavedBackLink] = useState<Path>()
  useEffect(() => {
    if (backLink) setSavedBackLink(backLink)
  }, [backLink])

  // if changing tab rather than leaving, don't warn before exit as state is saved
  const [changeTab, setChangeTab] = useState('')
  useEffect(() => {
    if (changeTab) {
      history.push(changeTab, { backLink: savedBackLink })
      setChangeTab('')
    }
  }, [changeTab])

  const render = !organisation ? (
    <Error>Organisation {id} not found.</Error>
  ) : (
    <>
      <OrgHeader
        organisation={organisation}
        changes={changes}
        saveOrg={saveOrg}
        backLink={savedBackLink}
      />
      <CardError>{errors[0] || cannotActivateMessage}</CardError>
      <Switch>
        <Route exact={true} path={orgPath}>
          <OrgDetails
            organisation={{ ...organisation, ...changes }}
            change={change}
          />
        </Route>
        <Route exact={true} path={Path.OrgFinance}>
          <OrgFinance
            organisation={{ ...organisation, ...changes }}
            change={change}
          />
        </Route>
      </Switch>
    </>
  )

  return (
    <Page
      warnBeforeExit={
        (!changeTab || changeTab.includes('/hosted')) && Boolean(actualChanges)
      }
    >
      <Card cardSize={CardSize.Medium}>
        <OrgTabs
          pathId={id}
          setChangeTab={setChangeTab}
          disabled={!organisation?.id}
        />
        {loading ? <Loading fullPage={true} /> : render}
      </Card>
    </Page>
  )
}
