import React, { ReactElement } from 'react'
import styled from 'styled-components'
import useBreadcrumbs, { BreadcrumbData } from 'use-react-router-breadcrumbs'
import { Link } from 'react-router-dom'
import { colors } from '@one-tree/library'

const StyledDiv = styled.div`
  color: ${colors.darkerGray};
  position: absolute;
  top: 6px;
  left: 8px;

  a {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    font-size: 0.9rem;
    transition: 0.2s;
    :hover {
      color: ${colors.black};
    }
  }
`

const getBreadcrumbs = (
  breadcrumbs: BreadcrumbData[],
  showThreshold: number,
): ReactElement[] | false => showThreshold < breadcrumbs.length
  && breadcrumbs.map((breadcrumb: BreadcrumbData, index: number) => {
    // Types are terrible with this lib.
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    const breadcrumbText = (breadcrumb.breadcrumb as ReactElement).props
      .children

    return (
      <span key={breadcrumb.key}>
        <Link to={breadcrumb.key}>{breadcrumbText.replace('-', ' ')}</Link>
        {breadcrumbs.length - 1 !== index && ' \u203a '}
      </span>
    )
  })

function Breadcrumbs(): ReactElement {
  const breadcrumbs = useBreadcrumbs()

  const renderBreadcrumbs = getBreadcrumbs(breadcrumbs, 1)

  return <StyledDiv>{renderBreadcrumbs}</StyledDiv>
}
export default Breadcrumbs
